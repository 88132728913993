<template>
  <v-app>
    <router-view></router-view>
    <v-overlay :value="!socketActive">
      <v-btn
        @click="
          createWebSocket();
          requestNotificationPermission();
        "
        width="150"
        class="ma-2"
        >Connect</v-btn
      >
      <v-edit-dialog @save="setPassphrase(passphrase)">
        <v-btn class="ma-2" width="150"><v-icon small>mdi-lock</v-icon></v-btn>
        <template v-slot:input>
          <v-text-field
            label="Passphrase"
            single-line
            v-model="passphrase"
          ></v-text-field>
        </template>
      </v-edit-dialog>
    </v-overlay>
  </v-app>
</template>

<script>
import trade from "./mixins/trades";

export default {
  name: "App",
  mixins: [trade],

  data: () => ({
    connection: null,
    connecting: false,
    //   outdated: false,
    passphrase: null,
    lastUpdateTimestamp: 0,
    nowTimestamp: 0,
  }),
  computed: {
    socketActive: function () {
      if (this.nowTimestamp - this.lastUpdateTimestamp > 6000) {
        return false;
      }
      if (
        this.connection === null ||
        this.connection.readyState !== WebSocket.OPEN
      ) {
        return false;
      }
      return true;
      // if (this.connection.readyState == 3) {
      //   return false;
      // }
      // return true;
    },
  },

  methods: {
    createWebSocket() {
      if (
        this.connecting ||
        (this.connection !== null &&
          (this.connection.readyState == WebSocket.CONNECTING ||
            this.connection.readyState == WebSocket.OPEN))
      ) {
        return;
      }

      console.log("Starting connection to WebSocket Server");

      let connectionURL =
        process.env.NODE_ENV == "production"
          ? "wss://prod.arbicrypto.com:8123/"
          : "wss://dev.arbicrypto.com:8123/";

      this.connecting = true;
      this.connection = new WebSocket(connectionURL);

      // eslint-disable-next-line no-unused-vars
      this.connection.onopen = (event) => {
        console.log("WebSocket Server connected!");
        this.connecting = false;
        this.$store.commit("updateConnection", this.connection);
        if (localStorage.keyphrase) {
          this.connection.send(localStorage.keyphrase);
        }
      };

      this.connection.onmessage = (event) => {
        try {
          this.parseSocketMessage(JSON.parse(event.data));
        }
        catch (e) {
          console.log("Error parsing socket message: " + e);
          console.log(event.data);
        }
      };

      // eslint-disable-next-line no-unused-vars
      this.connection.onerror = (event) => {
        console.log("WebSocket Server error.");
        this.connecting = false;
      };
    },
    parseSocketMessage(message) {
      this.lastUpdateTimestamp = Date.now();

      if (message.target == "notification") {
        var notificationArray = this.$store.state.notifications;
        notificationArray.push(message);
        this.$store.commit("updateNotifications", notificationArray);

        this.$notify({
          type: message.status.toLowerCase() + "-notification",
          title: message.status,
          text: message.message,
        });

        if (this.$route.path === "/desktop/trade" && message.message.includes("ROBOSELL")) {
          this.showNotification(message);
        }

        return;
      }

      if (message.action == "getTradeResponse") {
        this.pushTrade(message.data);
        return;
      }

      if (message.action == "downloadIanctivePairs") {
        this.downloadInactivePairs(message.data);
        return;
      }

      this.$store.commit("updateFromSocket", message);
    },
    downloadInactivePairs(data) {
      const binaryString = window.atob(data);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const jsonBlob = new Blob([bytes], {type: 'application/json'});
      
      const now = new Date();
      const formattedDate = now.toISOString().slice(0, 16); //.slice(0, 19).replace(/-/g, '').replace('T', '').replace(/:/g, '');
      const fileName = `ArbiInactivePairs_${formattedDate}.json`;

      const url = window.URL.createObjectURL(jsonBlob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
    sendMessage(messageContent) {
      this.connection.send(messageContent);
    },
    setPassphrase(passphrase) {
      localStorage.keyphrase = passphrase;
    },
    showNotification(message) {
      if (!("Notification" in window)) {
        console.log("Notifications not supported");
        return;
      }

      if (Notification.permission === "granted") {
        const options = { body: message.message };
        const notification = new Notification(message.status, options);
        notification.show();
      }
    },
    requestNotificationPermission() {
      if (!("Notification" in window)) {
        console.log("Notifications not supported");
        return;
      } else if (Notification.permission === "granted") {
        return;
      } else if (Notification.permission !== "denied") {
        Notification.requestPermission();
      }
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    },
    availabilityCheck() {
      setInterval(() => {
        this.nowTimestamp = Date.now();
        if (this.socketActive == false && this.connection.readyState != 0) {
          this.createWebSocket();
        }
      }, 5000);
    },
  },
  created() {
    this.createWebSocket();
    if (this.$router.currentRoute.path == "/") {
      this.isMobile()
        ? this.$router.push("/mobile")
        : this.$router.push("/desktop");
    }
    this.availabilityCheck();
  },
};
</script>

<style lang="scss">
// body {
//   zoom: 0.75;
// //  transform: scale(0.7);
// }

.pointer {
  cursor: pointer;
}

.v-small-dialog,
.v-small-dialog__activator {
  display: inline-block !important;
}

html {
  overflow: auto !important;
}

a:link,
a:visited,
a:active {
  text-decoration: none;
  color: black !important;
}
</style>
