<template>
  <v-list dense>
      <v-list-item-group mandatory v-model="tradeSelected" color="primary">
      <v-list-item v-for="(trade, index) in tradeList" :key="index" :value="index">
          <v-list-item-avatar tile size="30">
            <v-img :src="'https://s2.coinmarketcap.com/static/img/coins/64x64/' + trade.cmcId + '.png'"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h6 font-weight-bold">{{trade.coinName}} <v-chip class="float-right" x-small color="success" v-if="opportunitiesKeys.includes(trade.key)">LIVE</v-chip></v-list-item-title>
            <v-list-item-subtitle class="text-subtitle1">{{trade.source.exchange}} ({{trade.source.quoteCurrency}})  <v-icon>mdi-arrow-right-thin</v-icon> {{trade.destination.exchange}} ({{trade.destination.quoteCurrency}})</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
              <v-icon @click="close(trade)">mdi-close</v-icon>
          </v-list-item-action>
      </v-list-item>
      </v-list-item-group>
  </v-list>
</template>

<script>
export default {
    name: "TradeList",
    data: () => ({
        tradeSelected: null,
        tradeList: [],
    }),
    methods: {
        close(trade) {
            var tradeList = JSON.parse(localStorage.tradeList);

            var index = tradeList.map(function (element) {return element.key;}).indexOf(trade.key);
            if (index > -1) {
                tradeList.splice(index, 1);
            }

            localStorage.tradeList = JSON.stringify(tradeList);
            this.tradeList = tradeList;
        },
        onStorageUpdate(event) {
            if (event.key === 'tradeList') {
                this.tradeList = JSON.parse(localStorage.tradeList);
                //  var length = this.tradeList.length;
                //  if (length > 0) {
                //    this.tradeSelected = this.tradeList[0];
                //  }
            }
        },
    },
    mounted() {
        if (localStorage.tradeList) {
            this.tradeList = JSON.parse(localStorage.tradeList);
        }
        window.addEventListener("storage", this.onStorageUpdate);
    },
    beforeDestroy() {
        window.removeEventListener("storage", this.onStorageUpdate);
    },
    computed: {
        opportunitiesKeys: function() {
            var opportunities = this.$store.state.opportunities;
            return opportunities.map(function(opportunity) {
                return opportunity.key;
            });
        }
    },
     watch: {
        tradeSelected(trade) {
            if (trade === undefined) {
                this.$store.commit('updateTradeWindow', {});
                return;
            }
            this.$store.commit('updateTradeWindow', this.tradeList[trade]);
            this.$emit('changed');
        }
    }
}
</script>

<style scoped>

</style>