<template>
  <v-card>
    <v-card-text>
      <v-row class="px-5 pt-6">
        <v-col class="text-center justify-center align-center d-flex">
          <v-sheet>
            <div class="text-h6">Exchange Configuration</div>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select outlined :items="orderedExchanges" label="Exchange" v-model="destinationExchange"></v-select>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="destinationExchange != 'none'">
      <v-row>
        <v-col>
          <v-switch hide-details dense disabled label="API" input-value="true"></v-switch>
        </v-col>
        <v-col>
          <v-btn small color="success" @click="reloadApi()"><v-icon left> mdi-refresh </v-icon>Reload</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-switch hide-details dense label="Public WebSocket" v-model="exchangeConfiguration.wsPublic"></v-switch>
        </v-col>
        <v-col>
          <v-btn small color="success" @click="resetSockets('wsPublic')"
            ><v-icon left> mdi-refresh </v-icon>Reset</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-switch hide-details dense label="Private WebSocket" v-model="exchangeConfiguration.wsPrivate"></v-switch>
        </v-col>
        <v-col>
          <v-btn small color="success" @click="resetSockets('wsPrivate')"
            ><v-icon left> mdi-refresh </v-icon>Reset</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-switch hide-details dense label="Balance Cycle" v-model="exchangeConfiguration.balanceCycle"></v-switch>
        </v-col>
        <v-col>
          <v-text-field hide-details dense label="Balance value" v-model="exchangeConfiguration.balance"></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="pa-4">
      <v-spacer></v-spacer>
      <v-btn color="primary" outlined @click="close()">Cancel</v-btn>
      <v-btn color="primary" @click="confirm()" :disabled="destinationExchange == 'none'">Confirm</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "SocketsResetCard",
  data: () => ({
    destinationExchange: "none",
  }),
  methods: {
    confirm() {
      var message = {
        target: "sockets",
        action: "setExchangeConfiguration",
        exchange: this.destinationExchange,
        data: this.exchangeConfiguration,
      };

      if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
        this.$store.state.connection.send(JSON.stringify(message));
      }
      this.close();
    },
    resetSockets(type) {
      let actionMsg;
      switch (type) {
        case "wsPublic":
        default:
          actionMsg = "resetPublicExchangeSockets";
          break;
        case "wsPrivate":
          actionMsg = "resetPrivateExchangeSockets";
          break;
      }

      var message = {
        target: "sockets",
        action: actionMsg,
        exchange: this.destinationExchange,
      };

      if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
        this.$store.state.connection.send(JSON.stringify(message));
      }
      this.close();
    },
    reloadApi() {
      var message = {
        target: "sockets",
        action: "reloadApi",
        exchange: this.destinationExchange,
      };

      if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
        this.$store.state.connection.send(JSON.stringify(message));
      }
      this.close();
    },
    close() {
      Object.assign(this.$data, this.$options.data());
      this.$emit("close");
    },
  },
  computed: {
    orderedExchanges: function () {
      var exchanges = Object.assign({}, this.$store.state.balances);
      delete exchanges["TrustWallet"];
      delete exchanges["Trident"];
      return Object.keys(exchanges).sort();
    },
    exchangeConfiguration: function () {
      if (this.$store.state.exchangeConfiguration.exchange == this.destinationExchange) {
        return this.$store.state.exchangeConfiguration;
      }
      return null;
    },
  },
  watch: {
    destinationExchange: function () {
      if (this.destinationExchange != "none") {
        var message = {
          target: "sockets",
          action: "getExchangeConfiguration",
          exchange: this.destinationExchange,
        };
        if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
          this.$store.state.connection.send(JSON.stringify(message));
        }
      }
    },
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>