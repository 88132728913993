export default {
    methods: {
        addCommas(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ',' + '$2');
            }
            return x1 + x2;
          },
          decimalFloor(number, decimals) {
            if (decimals < 0) decimals = 0;
            var multiplier = Math.pow(10, decimals);
            return (Math.floor(number * multiplier) / multiplier).toFixed(decimals);
          },
          decimalRound(number, decimals) {
            if (decimals < 0) decimals = 0;
            var multiplier = Math.pow(10, decimals);
            return (Math.round(number * multiplier) / multiplier).toFixed(decimals);
          },
          decimalCeil(number, decimals) {
            if (decimals < 0) decimals = 0;
            var multiplier = Math.pow(10, decimals);
            return (Math.ceil(number * multiplier) / multiplier).toFixed(decimals);
          },
          addCommasAndFloor(number, decimals) {
            return this.addCommas(this.decimalFloor(number, decimals));
          },
          addCommasAndRound(number, decimals) {
            return this.addCommas(this.decimalRound(number, decimals));
          },
          reduceNumberSize(number, decimals) {
            let numberString = number.toString();
            let numberLenght = numberString.split('.')[0].length;
            if (numberLenght > 15) {
              return this.addCommasAndFloor(number / 1000000000000, 0) + " T";
            }
            if (numberLenght > 12) {
              return this.addCommasAndFloor(number / 1000000000, 0) + " B";
            }
            if (numberLenght > 9) {
              return this.addCommasAndFloor(number / 1000000, 0) + " M";
            }
            if (numberLenght > 6) {
              return this.addCommasAndFloor(number / 1000, 0) + " K";
            }
            if (number > 1 && decimals > 2) {
              decimals = 2;
            }
            return (this.addCommasAndFloor(number, decimals));
          },
          precisionToDecimal(precision) {
            return 1/Math.pow(10, precision);
          },

          stripZeroes(number) {
            number = number.toString();
        
            // Controlla se il numero è in notazione scientifica
            if (/e/.test(number)) {
                let [base, exponent] = number.split('e');
                exponent = Number(exponent); // Converte l'esponente in numero
        
                if (exponent < 0) {
                    let totalZeroes = Math.abs(exponent) - 1; // Calcola il numero totale di zeri
                    let significant = base.replace('.', '').substring(0, 5); // Estrai le cifre significative
                    return totalZeroes + '...' + significant;
                } else {
                    return number; // Per numeri grandi, restituisce il numero così com'è
                }
            }
        
            const rgx = /^0\.0+/;
            if (!rgx.test(number)) return number;
        
            const zeroes = number.match(rgx)[0].split('.')[1].length;
            const significant = number.substring(zeroes + 2, zeroes + 7); // Modifica qui
        
            return zeroes + '...' + significant;
        },

        formatNumberForView(number) {
          if (number == 0) {
            return 0;
          }
          if (number > 0 && number < 0.1)
          {
            return this.stripZeroes(number);
          }

          if (number >= 0.1) {
            number = number.toString();
            return this.addCommas(number.substring(0, 7));
          }
        },

          getDecimalPlaces(number) {
            if (Number.isInteger(number)) return 0;
            if (number.toString().includes("e")) number = number.toFixed(8);
            return number.toString().split('.')[1].length;
          },
          getDecimalStep(number) {
            let decimalPlaces = this.getDecimalPlaces(number);
            if (decimalPlaces >= 6) decimalPlaces = 6;
            return this.precisionToDecimal(decimalPlaces);
          }
    }
}