export default {
    methods: {
        pushTrade(trade) { 
            var tradeList = [];
            if (localStorage.tradeList) {
              tradeList = JSON.parse(localStorage.tradeList);
            }
      
            var match = tradeList.find(tradeElement => tradeElement.key == trade.key);
            if (match !== undefined) {
              tradeList[tradeList.indexOf(match)] = trade;
            }
            else{
              tradeList.unshift(trade);
            }

            this.$store.commit('updateTradeWindow', trade);
            
            localStorage.tradeList = JSON.stringify(tradeList);
            window.dispatchEvent( new StorageEvent('storage', {
                key: 'tradeList',
            }));
          },
        updateLiveTrade(opportunities) {
            // var tradeList = [];
             var liveTrade = {
                avgBuyPrice: 0,
                maxBuyPrice: 0,
                avgSellPrice: 0,
                maxBuyable: 0,
                actualProfit: 0,
                maxProfit:0,
            } ; // = this.$store.state.tradeWindow;

            // if (localStorage.tradeList) {
            //     tradeList = JSON.parse(localStorage.tradeList);
            // }

            opportunities.forEach(opportunity => {
                if (this.$store.state.tradeWindow.key == opportunity.key) {
                    liveTrade = opportunity.details;
                }

                // let match = tradeList.find(tradeElement => tradeElement.key == opportunity.key);

                // if (match !== undefined) {
                //     tradeList[tradeList.indexOf(match)].liveData = opportunity.details;
                //     localStorage.tradeList = JSON.stringify(tradeList);
                //     // window.dispatchEvent( new StorageEvent('storage', {
                //     //     key: 'tradeList',
                //     // }));
                // }
            });

             return liveTrade;

        },
        pushCurrentTrade() {
            var liveTrade = this.$store.state.tradeWindow;

            var message = {
                target: "opportunities",
                action: "getTrade",
                currency: liveTrade.coinName,
                source: liveTrade.source.exchange + '|' + liveTrade.source.symbol,
                destination: liveTrade.destination.exchange + '|' + liveTrade.destination.symbol,
                mode: "push",
              }
              if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        }
    }
}