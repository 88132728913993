<template>
    <div class="pa-3">
        <v-tabs v-model="tab" grow @change="refresh()">
            <v-tab>Open Orders (S)</v-tab>
            <v-tab>Orders History (S)</v-tab>
            <v-tab>Withdrawals (S)</v-tab>
            <v-divider vertical></v-divider>
            <v-tab>Open Orders (D)</v-tab>
            <v-tab>Orders History (D)</v-tab>
            <v-tab>Deposits (D)</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" touchless>
            <v-tab-item key="openOrdersS">
                <OrderTable :exchange="tradeWindow.source.exchange" :baseCurrency="tradeWindow.source.baseCurrency" :quoteCurrency="tradeWindow.source.quoteCurrency" :history="false" :tickPrecision="tradeWindow.source.tickPrecision" :lotPrecision="tradeWindow.source.lotPrecision" />
            </v-tab-item>
            <v-tab-item key="ordersHistoryS">
                <OrderTable :exchange="tradeWindow.source.exchange" :baseCurrency="tradeWindow.source.baseCurrency" :quoteCurrency="tradeWindow.source.quoteCurrency" :history="true" :tickPrecision="tradeWindow.source.tickPrecision" :lotPrecision="tradeWindow.source.lotPrecision" />
            </v-tab-item>
            <v-tab-item key="withdraw">
                <TransferTable :exchange="tradeWindow.source.exchange" :currency="tradeWindow.source.baseCurrency" :type="'withdraw'" />
            </v-tab-item>
            <v-tab-item key="openOrdersD">
                <OrderTable :exchange="tradeWindow.destination.exchange" :baseCurrency="tradeWindow.destination.baseCurrency" :quoteCurrency="tradeWindow.destination.quoteCurrency" :history="false" :tickPrecision="tradeWindow.destination.tickPrecision" :lotPrecision="tradeWindow.destination.lotPrecision" />
            </v-tab-item>
            <v-tab-item key="ordersHistoryD">
                <OrderTable :exchange="tradeWindow.destination.exchange" :baseCurrency="tradeWindow.destination.baseCurrency" :quoteCurrency="tradeWindow.destination.quoteCurrency" :history="true" :tickPrecision="tradeWindow.destination.tickPrecision" :lotPrecision="tradeWindow.destination.lotPrecision" />
            </v-tab-item>
            <v-tab-item key="deposit">
                <TransferTable :exchange="tradeWindow.destination.exchange" :currency="tradeWindow.destination.baseCurrency" :type="'deposit'" />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>

<script>
import OrderTable from '../OrderTable.vue';
import TransferTable from '../TransferTable.vue';

export default {
    name: 'TradeDetails',
    components: {
        OrderTable,
        TransferTable,
    },
    data: () => ({
        tab: 'openOrdersS',
        intervalFn: null,
    }),
    methods: {
        updateOpenOrders(tradeWindow) {
            var message = {
                target: 'trade',
                action: 'updateOpenOrders',
                exchange: tradeWindow.exchange,
                baseCurrency: tradeWindow.baseCurrency,
                quoteCurrency: tradeWindow.quoteCurrency,
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        },
        getOrderHistory(exchange, market) {
            var message = {
                target: 'trade',
                action: 'getOrderHistory',
                exchange: exchange,
                market: market,
            };
            
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        },
        getTransferHistory(exchange, currency, type) {
            var action = (type == 'deposit') ? 'getDepositHistory' : 'getWithdrawHistory';
            var message = {
                target: 'wallet',
                action: action,
                exchange: exchange,
                currency: currency,
            };   
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        },
        refresh() {
            if (this.tab == 0) {
                this.updateOpenOrders(this.tradeWindow.source);
                return;
            }
            if (this.tab == 1) {
                this.getOrderHistory(this.tradeWindow.source.exchange, this.tradeWindow.source.symbol);
                return;
            }
            if (this.tab == 2) {
                this.getTransferHistory(this.tradeWindow.source.exchange, this.tradeWindow.source.baseCurrency, 'withdraw');
                return;
            }
            if (this.tab == 3) {
                this.updateOpenOrders(this.tradeWindow.destination);
            }
            if (this.tab == 4) {
                this.getOrderHistory(this.tradeWindow.destination.exchange, this.tradeWindow.destination.symbol);
                return;
            }
            if (this. tab == 5) {
                this.getTransferHistory(this.tradeWindow.destination.exchange, this.tradeWindow.destination.baseCurrency, 'deposit');
                return;
            }
        }
    },
    computed: {
        tradeWindow: function() {
            return this.$store.state.tradeWindow;
        },
    },
    watch: {
        tradeWindow: function() {
            this.refresh();
        }
    },
    mounted() {
       this.intervalFn = setInterval(() => {this.refresh();}, 3000);
    },
    beforeDestroy() {
        clearInterval(this.intervalFn);
    }
}
</script>

<style scoped>
* >>> .v-slide-group__prev--disabled {
    display: none !important;
}
</style>