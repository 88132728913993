<template>
  <v-card>
        <v-card-text>
            <v-row class="px-5 pt-6">
                <v-col class="text-center justify-center align-center d-flex">
                  <v-text-field placeholder="Currency" v-model="searchString"></v-text-field>
                  <v-btn small color="primary" class="ml-2 mt-4" @click="search()"><v-icon>mdi-magnify</v-icon>Search</v-btn>
                </v-col>
            </v-row>
            <v-row v-if="!isResponseEmpty()">
              <v-col align="center">
                <div>
                  <v-img width="40" :src="'https://s2.coinmarketcap.com/static/img/coins/64x64/' + books.cmcId + '.png'" class="d-inline-block align-center"></v-img>
                  <div class="text-h4 font-weight-bold ml-3 d-inline-block">{{ books.currency }}</div>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="!isResponseEmpty()">
              <v-col>
                <v-select label="Source" v-model="source" :items="books.books" :item-text="item => item.baseCurrency + ' - ' + item.cmcId + ' - ' + item.exchange + ' (' +  item.quoteCurrency + ')'" :item-value="item => item.exchange + '|' + item.symbol"></v-select>
                <v-select label="Destination" v-model="destination" :items="books.books" :item-text="item => item.baseCurrency + ' - ' + item.cmcId + ' - ' + item.exchange + ' (' +  item.quoteCurrency + ')'" :item-value="item => item.exchange + '|' + item.symbol"></v-select>
              </v-col>
            </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="close()">Cancel</v-btn>
            <v-btn color="primary" @click="confirm()" :disabled="source == null || destination == null">Confirm</v-btn>
        </v-card-actions>
      </v-card>
</template>

<script>
export default {
    name: "TradeCard",
    data: () => ({
      searchString: null,
      source: null,
      destination: null,
    }),
    computed: {
      books: function () {
        return this.$store.state.getBooksResponse;
      }
    },
    methods: {

      search() {
        this.$store.commit('resetGetBookResponse');
        this.source = null;
        this.destination = null;
        var message = {
          target: "opportunities",
          action: "getBooks",
          currency: this.searchString,
        }

            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }

      },

      confirm() {
        var message = {
          target: "opportunities",
          action: "getTrade",
          currency: this.books.currency,
          source: this.source,
          destination: this.destination,
          mode: "pull",
        }

            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        this.close();
      },
      isResponseEmpty() {
        return Object.keys(this.books).length === 0 ? true : false;
      },
      close() {
        this.$store.commit('resetGetBookResponse');
        Object.assign(this.$data, this.$options.data());
        this.$emit('close');
      },
      resetAmount() {
        this.amount = 0;
      }
    }
}
</script>

<style scoped>

</style>