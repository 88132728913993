<template>
  <v-list two-line dense>
    <template v-for="(transfer, index) in transferList">
      <v-list-item :key="index">
        <!-- <v-list-item-avatar tile size="30">
                <v-chip class="float-right" x-small color="success">BUY</v-chip>
            </v-list-item-avatar> -->
        <v-list-item-content>
          <v-list-item-title>{{ transfer.timestamp | dateTime }} | Status: {{ transfer.status }}</v-list-item-title>
          <v-list-item-subtitle class="text-subtitle1"
            >Amount: {{ addCommas(transfer.amount) }} | Fee: {{ addCommas(transfer.fee) }}</v-list-item-subtitle
          >
          <v-list-item-subtitle class="text-subtitle1"
            >Address: {{ transfer.address | truncate(12, 6, 4, ".....") }}
            <v-icon small @click="copy2Clipboard(transfer.address)">mdi-content-copy</v-icon></v-list-item-subtitle
          >
          <v-list-item-subtitle class="text-subtitle1">
            Tx Hash:
            <a v-if="transfer.txLink" :href="transfer.txLink" target="_blank">
              {{ transfer.txHash | truncate(12, 6, 6, ".....") }}
            </a>
            <span v-else>
              {{ transfer.txHash | truncate(12, 6, 6, ".....") }}
            </span>
            <v-icon small @click="copy2Clipboard(transfer.txHash)"> mdi-content-copy </v-icon></v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
      <v-divider :key="index" v-if="index < Object.keys(transferList).length - 1"></v-divider>
    </template>
  </v-list>
</template>

<script>
export default {
  name: "MobileTransferList",
  props: ["exchange", "currency", "type"],
  computed: {
    transferList: function () {
      var transfersArray;
      if (this.type == "deposit") {
        transfersArray = this.$store.state.depositHistory;
      } else {
        transfersArray = this.$store.state.withdrawHistory;
      }
      transfersArray = transfersArray.filter(
        (transfer) => transfer.currency == this.currency && transfer.exchangeString == this.exchange
      );
      return transfersArray.sort(function (x, y) {
        return y.timestamp - x.timestamp;
      });
    },
  },
};
</script>

<style scoped>
</style>