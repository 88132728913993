<template>
    <v-card>
        <div class="pt-8">
            <div class="text-h6 text-center">{{tradeWindow.source.exchange}} ({{tradeWindow.source.baseCurrency}}/{{tradeWindow.source.quoteCurrency}}) - 1m</div>
            <CandleChart :role="'source'" interval="1m" :type="'mobile'" />
        </div>
        <div class="pt-4">
            <div class="text-h6 text-center">{{tradeWindow.destination.exchange}} ({{tradeWindow.destination.baseCurrency}}/{{tradeWindow.destination.quoteCurrency}}) - 1m</div>
            <CandleChart :role="'destination'" interval="1m" :type="'mobile'" />
        </div>
        <v-card-actions class="pa-4">
            <!-- <v-checkbox v-model="closeAfterConfirm" label="Close dialog after confirm"></v-checkbox> -->
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="close()">Close</v-btn>
            <!-- <v-btn color="primary" @click="confirm()" :disabled="amount == 0 || destinationExchange == 'none'">Confirm</v-btn> -->
        </v-card-actions>
    </v-card>
</template>

<script>
import CandleChart from '../CandleChart.vue'

export default {
    name: "MobileChartsCard",
    components: { CandleChart },
    methods: {
        close() {
        //    Object.assign(this.$data, this.$options.data());
            this.$emit('close');
        },
    },
    computed: {
        tradeWindow: function() {
            return this.$store.state.tradeWindow;
        }
    }
}
</script>

<style scoped>

</style>