<template>
<div>
<v-app-bar
      app
      :color="isProduction() ? 'primary' : 'amber'"
      dark
      clipped-right
    >
      <div class="d-flex align-center">
        <h2 class="d-none d-sm-flex">ArbiCrypto / Opportunities</h2>
      </div>

      <v-spacer></v-spacer>
      <div class="font-weight-light mr-8">
        <v-icon class="mr-5" @click="downloadInactives()">mdi-download</v-icon>
         <v-icon class="mr-5" @click="openSocketsResetDialog()">mdi-connection</v-icon>
        <v-icon class="mr-8" @click="redistributeUsdt()">mdi-bank-transfer</v-icon>
         <v-icon :class="{'mr-5': true, 'grey--text': !usdtPrices}" @click="toggleUsdtPrices()"
          >mdi-currency-usd</v-icon
        >
        <v-icon :class="{'mr-5': true, 'grey--text': !moveFunds}" @click="toggleMoveFunds()">mdi-swap-horizontal-variant</v-icon>
        <v-icon :class="{'mr-5': true, 'grey--text': !multiScreen}" @click="multiScreen = !multiScreen">mdi-monitor-multiple</v-icon>
         <v-icon :class="{'mr-5': true, 'grey--text': !filterMuted}" @click="filterMuted = !filterMuted">mdi-filter-outline</v-icon>
        <v-icon :class="{'mr-2': true, 'grey--text': !sound}" @click="toggleSound()">mdi-volume-high</v-icon>
        <v-icon :class="{'mr-5': true, 'grey--text': !night}" @click="toggleNight()">mdi-weather-night</v-icon>
        <v-icon :class="{'mr-3': true, 'grey--text': !privacy}" @click="togglePrivacy()">mdi-eye-off-outline</v-icon>
      </div>
      <div class="font-weight-light mr-16">
        <v-badge :content="this.$store.state.notifications.length" :value="this.$store.state.notifications.length" color="red" overlap>
      <v-menu v-model="notificationCard">
      <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-bell-outline</v-icon>
        </template>
        <v-card>
          <v-card-title>Notifications</v-card-title>
          <v-card-text>
          <NotificationList />
          </v-card-text>
          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="clearNotifications()">Clear all</v-btn>
          </v-card-actions> -->
        </v-card>
      

      </v-menu>
       </v-badge>
      </div>
      <div class="text-h6 min-width-200 text-right">
      <v-menu
      :close-on-content-click="false"
      :nudge-width="150"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on">Balance: {{(privacy) ? '*****' : addCommasAndFloor(totalBalance, 0)}} &dollar;</span>
    </template>
      
    <v-card>
      <BalanceList />
    </v-card>
    </v-menu>  
    </div>
      <div>
        <v-icon large class="ml-3" @click="drawer = !drawer" v-if="!drawer">mdi-menu-open</v-icon>
        <v-icon large class="ml-3" @click="drawer = !drawer" v-else>mdi-menu-open mdi-rotate-180</v-icon>
      </div>
    </v-app-bar>
        <v-navigation-drawer clipped app right v-model="drawer">
        <ExchangeList @open-transfer-dialog="openTransferDialog"/>
    </v-navigation-drawer>
    <v-main>
  <v-container fluid>
    <v-row class="text-center">
      <v-col cols="12">
      <v-alert v-if="!updating" border="left" outlined prominent type="error">
        Opportunities not updating correctly.
    </v-alert>
        <v-data-table
          dense
          :headers="headers"
          :hide-default-footer="opportunities.length < 25"
          :items="opportunities"
          :items-per-page=25
          sort-by="actualProfit"
          :sort-desc="true"
          :item-class="getRowClass"
          class="elevation-1"
          :footer-props="{
            itemsPerPageOptions: [
            25, 50, 100, -1
            ],
            showFirstLastPage: true,
          }"
          no-data-text="No opportunities available"
        >
          <template v-slot:[`item.trade`]="{ item }">
            <v-icon class="pointer" @click="addToTradeList(item)">mdi-cash-multiple</v-icon>
          </template>
          <template v-slot:[`item.coinName`]="{ item }">
            <a :href="'https://coinmarketcap.com/currencies/' + item.cmcSlug + '/markets/'" target="_blank">
            <img
              :src="
                'https://s2.coinmarketcap.com/static/img/coins/16x16/' +
                item.cmcId +
                '.png'
              " style="width: 16px; height: 16px;"
            /><span class="coinName">&nbsp;{{ item.coinName }}</span><v-icon v-if="item.deflationary" small color="error">mdi-arrow-bottom-right-thick</v-icon></a>
          </template>
          <template v-slot:[`item.withdrawAllowed`]="{ item }">
            <a
              :href="getWithdrawLink(item.sourceExchange, item.coinName)"
              target="_blank"
              ><v-icon
                small
                :color="getwithdrawDepositColor(item.withdrawAllowed)"
                >mdi-export</v-icon
              ></a
            >
          </template>
          <template v-slot:[`item.buyFrom`]="{ item }">
            <a
              :href="getMarketLink(item.sourceExchange, item.coinName, item.sourceQuote)"
              target="_blank"
              >{{item.sourceExchange}} ({{item.sourceQuote}})</a
            >
          </template>
          <template v-slot:[`item.depositAllowed`]="{ item }">
            <a
              :href="getDepositLink(item.destinationExchange, item.coinName)"
              target="_blank"
              ><v-icon
                small
                :color="getwithdrawDepositColor(item.depositAllowed)"
                >mdi-import</v-icon
              ></a
            >
          </template>
          <template v-slot:[`item.sellTo`]="{ item }">
            <a
              :href="getMarketLink(item.destinationExchange, item.coinName, item.destinationQuote)"
              target="_blank"
              >{{item.destinationExchange}} ({{item.destinationQuote}})</a
            >
          </template>
          <template v-slot:[`item.cap`]="{ item }">
            <v-chip
              v-if="item.details.cap == 'BUY'"
              color="green"
              text-color="white"
              x-small
              >{{ item.details.cap }}</v-chip
            >
            <v-chip
              v-if="item.details.cap == 'SELL'"
              color="red"
              text-color="white"
              x-small
              >{{ item.details.cap }}</v-chip
            >
          </template>
          <template v-slot:[`item.avgBuyPrice`]="{ item }">
            {{ addCommasAndFloor(item.details.avgBuyPrice, 5) }} &dollar;
          </template>
          <template v-slot:[`item.avgSellPrice`]="{ item }">
            {{ addCommasAndFloor(item.details.avgSellPrice, 5) }} &dollar;
          </template>
          <template v-slot:[`item.maxBuyable`]="{ item }">
            {{ addCommasAndFloor(item.details.maxBuyable, 0) }} &dollar;
          </template>
          <template v-slot:[`item.maxProfit`]="{ item }">
            {{ addCommasAndFloor(item.details.maxProfit, 0) }} &dollar;
          </template>
          <template v-slot:[`item.minProfitPercent`]="{ item }">
            {{ addCommasAndFloor(item.details.minProfitPercent, 1) }} &percnt;
          </template>
          <template v-slot:[`item.actualProfit`]="{ item }">
            {{ addCommasAndFloor(item.details.actualProfit, 0) }} &dollar;
          </template>
          <template v-slot:[`item.actualProfitPercent`]="{ item }">
            {{ addCommasAndFloor(item.details.actualProfitPercent, 1) }} &percnt;
          </template>
          <template v-slot:[`item.withdrawFee`]="{ item }">
            {{ addCommasAndFloor(item.withdrawFee, 2) }} &dollar;
          </template>
          <template v-slot:[`item.more`]="{ item }">
            <v-menu min-width="200" offset-x left>
              <template v-slot:activator="{ on, attrs }">
                 <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-card>
              <div class="pa-3">
                Last update: {{ addCommasAndFloor(item.lastUpdate / 1000, 0) }} s
              </div>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item @click="openOpportunityConfigDialog(item)">
                  <v-list-item-icon>
                  <v-icon>mdi-cog-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Configuration</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              </v-card>
            </v-menu>
           
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
   </v-main>
    <v-dialog v-model="transferDialog" max-width="500">
      <TransferCard :sourceExchange="transferDialogExchange" @close="closeTransferDialog()" />
    </v-dialog>
    <v-dialog v-model="socketsResetDialog" max-width="500">
      <SocketsResetCard @close="closeSocketsResetDialog()" />
    </v-dialog>
    <v-dialog v-model="opportunityConfigDialog" max-width="500">
      <OpportunityConfigCard :opportunity="opportunityConfigDialogOpportunity" @close="closeOpportunityConfigDialog()" v-if="opportunityConfigDialogOpportunity"  />
    </v-dialog>
    <v-dialog v-model="tradeWindow" scrollable max-width="1800" eager>
      <v-card tile>
        <v-card-text class="pa-6">
            <DesktopTradeWindow v-if="tradeWindow" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BalanceList from '../components/BalanceList.vue';
import NotificationList from '../components/NotificationList.vue';
import ExchangeList from '../components/ExchangeList.vue';
import TransferCard from '../components/TransferCard.vue';
import SocketsResetCard from '../components/SocketsResetCard.vue';
import OpportunityConfigCard from '../components/OpportunityConfigCard.vue';
import DesktopTradeWindow from '../components/desktop/DekstopTradeWindow.vue';

import toggles from '../../../mixins/toggles';
import trades from '../../../mixins/trades';
import balance from '../../../mixins/balance';


export default {
  name: "DesktopOpportunities",
  mixins: [toggles, trades, balance],
  components: {
      BalanceList,
      NotificationList,
      ExchangeList,
      TransferCard,
      SocketsResetCard,
      OpportunityConfigCard,
      DesktopTradeWindow,
  },
  computed: {
      // totalBalance: function() {
      //   var totalUsdtBalance = 0;
      //     for (var i in this.$store.state.balances) {
      //       if (this.$store.state.balances[i] === null) continue;
      //       totalUsdtBalance += parseFloat(this.$store.state.balances[i]['USDT']);
      //     }
      //    return this.addCommasAndFloor(totalUsdtBalance, 0);
      // },
      moveFunds: function() {
        return this.$store.state.moveFundsFlag;
      },
      usdtPrices: function() {
        return this.$store.state.usdtPricesFlag;
      },
      opportunities: function() {
        if (this.filterMuted) {
          return this.$store.state.opportunities.filter(opportunity => !opportunity.muted || opportunity.details.actualProfit > 300);
        }
        return this.$store.state.opportunities;
      },
      updating: function() {
        if ((this.nowTimestamp - this.$store.state.opportunitiesLastUpdate) > 10000) {
        return false;
      }
      return true;
      }
    },

  watch: {
    notificationCard: function () {
      if (this.notificationCard == false) {
        this.clearNotifications();
      }
    }
  },
  methods: {
            isProduction() {
            return process.env.NODE_ENV == 'production' ? true : false;
        },
    addToTradeList(trade) { 
//      this.pushTrade(trade);

        this.$store.commit("updateTradeWindow", {});

        var message = {
          target: "opportunities",
          action: "getTrade",
          currency: trade.coinName,
          source: trade.sourceExchange + '|' + trade.sourceSymbol,
          destination: trade.destinationExchange + '|' + trade.destinationSymbol,
          mode: "pull",
        }

            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }

      if (this.multiScreen == false) {
         this.tradeWindow = true;
      }
    },
    clearNotifications() {
      this.$store.commit('updateNotifications', []);
    },
    openTransferDialog(exchange) {
      this.transferDialogExchange = exchange;
      this.transferDialog = true;
    },
    closeTransferDialog() {
      this.transferDialog = false;
    },
    openSocketsResetDialog() {
      this.socketsResetDialog = true;
    },
    closeSocketsResetDialog() {
      this.socketsResetDialog = false;
    },
    openOpportunityConfigDialog(item) {
      this.opportunityConfigDialogOpportunity = item;
      this.opportunityConfigDialog = true;
    },
    closeOpportunityConfigDialog() {
      this.opportunityConfigDialog = false;
    },
    getwithdrawDepositColor(value) {
      switch (value) {
        case "ALLOWED":
          return "green";
        // case "NOT_ALLOWED":
        //   return "red";
        case "NOT_AVAILABLE":
          return "amber";
      }
    },
    downloadInactives() {
        var message = {
        target: "sockets",
        action: "downloadInactivePairs",
        exchange: "na",
      };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
    },
    redistributeUsdt() {
      var message = {
        target: "wallet",
        action: "redistributeUsdt",
        exchange: "defaultSource",
      };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
    },
    toggleSound() {
      this.night = false;
      this.sound = !this.sound;
    },
    toggleNight() {
      this.sound = false;
      this.night = !this.night;
    },
    togglePrivacy() {
      var privacy = this.privacy;
      if (localStorage.privacy) {
        privacy = JSON.parse(localStorage.privacy);
      }
      privacy = !privacy;
      this.privacy = privacy;
      localStorage.privacy = JSON.stringify(privacy);
      window.dispatchEvent( new StorageEvent('storage', {
        key: 'privacy',
      }) );
    },
    getRowClass(item) {
      let classes = "";
      if (item.muted) classes += "opaque ";
      if (item.lastUpdate > 300000 && !(item.sourceExchange.includes('OneInch') || item.destinationExchange.includes('OneInch'))) classes += "oldRefresh ";
      return classes.slice(0, -1);
    },
    notify(){
      setInterval(() => {
        this.nowTimestamp = Date.now();
        let play = false;
        this.opportunities.forEach(opportunity => {
          if (opportunity.actualProfit > 100 && this.sound && !opportunity.muted) {
            play = true;
          }
          if (opportunity.maxProfit > 1000 && opportunity.minProfitPercent > 20 && this.night && !opportunity.muted) {
            play = true; 
          }
        });
        if (play) {
          this.notificationSound.play();
        }
      }, 5000);
    },
    // onStorageUpdate(event) {
    //   if (event.key == 'privacy') {
    //     this.privacy = JSON.parse(localStorage.privacy);
    //   }
    // }
  },
  data: () => ({
    notificationSound: new Audio(require('@/assets/notification.mp3')),
    transferDialogExchange: 'none',
    transferDialog: false,
    socketsResetDialog: false,
    opportunityConfigDialog: false,
    tradeWindow: false,
    nowTimestamp: 0,
//    moveFunds: false,
    multiScreen: false,
    notificationCard: false,
    // balanceCard: false,
    filterMuted: true,
    drawer: true,
    sound: false,
    night: false,
    privacy: false,
    headers: [
      { text: "Trade", value: "trade", align: "center", sortable: false, width: '60px' },
      { text: "Crypto", value: "coinName" },
      {
        text: "Withdraw",
        value: "withdrawAllowed",
        align: "center",
        sortable: false,
        width: '60px'
      },
      { text: "Buy from", value: "buyFrom", sortable: false },
      {
        text: "Deposit",
        value: "depositAllowed",
        align: "center",
        sortable: false,
        width: '60px'
      },
      { text: "Sell to", value: "sellTo", sortable: false },
      { text: "Cap", value: "cap", align: "center", sortable: false },
      {
        text: "Avg buy price",
        value: "avgBuyPrice",
        align: "end",
        sortable: false,
      },
      {
        text: "Avg sell price",
        value: "avgSellPrice",
        align: "end",
        sortable: false,
      },
      { text: "Max buyable", value: "maxBuyable", align: "end" },
      { text: "Max profit", value: "maxProfit", align: "end" },
      { text: "Min profit %", value: "minProfitPercent", align: "end" },
      { text: "Actual profit", value: "actualProfit", align: "end" },
      { text: "Actual %", value: "actualProfitPercent", align: "end" },
      { text: "Fees", value: "withdrawFee", align: "end", sortable: false},
      { text: "More", value: "more", align: "center", sortable: false, width: '60px' },
    ],
    wdStatuses: [
      { text: "Allowed", value: "ALLOWED" },
      { text: "Not allowed", value: "NOT_ALLOWED" },
      { text: "N/A", value: "NOT_AVAILABLE" },
    ],
  }),
  created() {
    this.notify();
  },
};
</script>

<style scoped>
.coinName {
  font-weight: bold;
  line-height: 16px;
}

.min-width-200 {
  min-width: 200px;
}

* >>> .opaque {
  opacity: 0.2 !important;
}

* >>> .oldRefresh {
  background-color: #fdffcd !important;
}

</style>