<template>
  <v-card>
    <v-card-text>
            <v-row class="px-5 pt-6">
                <v-col class="text-center justify-center align-center d-flex">
                <v-sheet>
                  <div class="text-h6">Confirm Swap?</div>
                </v-sheet>
                </v-col>
            </v-row>
<v-row>
  <v-col cols="12">
    <h3>Token name:</h3>
    <p>{{ swapResponse.destTokenName }}</p>
  </v-col>
  <v-col cols="12">
    <h3>Token address:</h3>
    <p><a :href="swapResponse.exchange.includes('ETH') ? 'https://etherscan.io/address/' + swapResponse.destTokenContract : 'https://bscscan.com/address/' + destTokenContract" target="_blank"> {{ swapResponse.destTokenContract }}</a></p>
  </v-col>
  <v-col cols="6">
    <h3>Input amount:</h3>
    <p>{{ swapResponse.inputAmount }}</p>
  </v-col>
  <v-col cols="6">
    <h3>Output amount:</h3>
    <p>{{ swapResponse.outputAmount }}</p>
  </v-col>
  <v-col cols="12">
    <h3>Destination address:</h3>
    <p>{{ swapResponse.destination }}</p>
  </v-col>
  <v-col cols="12">
    <h3>Price:</h3>
    <p>{{ swapResponse.price }}</p>
  </v-col>
</v-row>
        </v-card-text>
        <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="close()">Cancel</v-btn>
            <v-btn color="primary" @click="confirm()">Confirm</v-btn>
        </v-card-actions>
  </v-card>
</template>

<script>
export default {
    name: "SwapCard",
    computed: {
        swapResponse: function() {
            return this.$store.state.swapResponse;
        },
    },
    methods: {
        confirm() {
            var message = {
                target: "trade",
                exchange: this.swapResponse.exchange,
                action: "confirmSwap",
                txData: this.swapResponse.apiResponse
            };

            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }

            this.$emit('close');

        },
        close() {
            this.$emit('close');
        },
    }
}
</script>

<style scoped>

</style>