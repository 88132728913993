<template>
  <v-col>
    <v-row>
      <v-col class="d-flex align-center">
        <div>
          <v-img
            :src="
              'https://s2.coinmarketcap.com/static/img/exchanges/64x64/' +
              getExchangeId(configuration.exchange) +
              '.png'
            "
            class="mr-2 d-inline-block pointer"
            @click="copy2FA(configuration.exchange)"
            height="36"
            width="36"
          ></v-img>
        </div>
        <div class="text-h6 font-weight-bold">
          {{ configuration.exchange }}
          <a
            :href="getMarketLink(configuration.exchange, configuration.baseCurrency, configuration.quoteCurrency)"
            target="_blank"
            >({{ configuration.baseCurrency }}/{{ configuration.quoteCurrency }})</a
          >
        </div>
        <div class="text-subtitle1 ml-4">
          <a :href="getWithdrawLink(configuration.exchange, configuration.baseCurrency)" target="_blank"
            ><v-icon class="mr-2">mdi-export</v-icon>Withdraw</a
          >
        </div>
        <div class="text-subtitle1 ml-4">
          <a :href="getDepositLink(configuration.exchange, configuration.baseCurrency)" target="_blank"
            ><v-icon class="mr-2">mdi-import</v-icon>Deposit</a
          >
        </div>
        <v-spacer></v-spacer>
        <div class="text-subtitle1" v-if="configuration.quoteCurrency != 'USDT'">
          <v-icon class="mr-2" @click="openConversionDialog()">mdi-swap-horizontal-circle-outline</v-icon>
        </div>
        <div class="text-subtitle1">
          <v-row dense
            ><v-col class="py-0 px-2 text-right"
              >{{ addCommasAndFloor(quoteCurrencyBalance, getDecimalPositions(configuration.quoteCurrency)) }}
              {{ configuration.quoteCurrency }}</v-col
            ></v-row
          >
          <v-row dense
            ><v-col class="py-0 px-2 text-right"
              >{{ addCommasAndFloor(baseCurrencyBalance, configuration.lotPrecision) }}
              {{ configuration.baseCurrency }}</v-col
            ></v-row
          >
        </div>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col class="col-8">
        <v-row>
          <v-col>
            <CandleChart :role="configuration.role.toLowerCase()" interval="1m" :type="'desktop'"></CandleChart>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <v-text-field outlined dense hide-details class="mb-2" label="Price" v-model="priceBuy"></v-text-field>
            <v-text-field
              outlined
              dense
              hide-details
              class="mb-2"
              label="Amount"
              v-model="amountBuy"
              :suffix="`~ ${addCommasAndFloor(amountBuy * priceBuy, 0)} $`"
            ></v-text-field>
            <v-slider
              hide-details
              :step="configuration.lotPrecision <= 6 ? precisionToDecimal(configuration.lotPrecision) : 0.000001"
              class="mb-2"
              v-model="amountBuy"
              :max="maxBuyableAmount"
            ></v-slider>
            <v-row dense class="mb-1">
              <v-col
                ><v-btn
                  block
                  :outlined="!live || configuration.role != 'SOURCE'"
                  small
                  :color="live && configuration.role == 'SOURCE' ? 'success' : 'primary'"
                  @click="updateBuySlider(0.25)"
                  >25%</v-btn
                ></v-col
              >
              <v-col
                ><v-btn
                  block
                  :outlined="!live || configuration.role != 'SOURCE'"
                  small
                  :color="live && configuration.role == 'SOURCE' ? 'success' : 'primary'"
                  @click="updateBuySlider(0.5)"
                  >50%</v-btn
                ></v-col
              >
              <v-col
                ><v-btn
                  block
                  :outlined="!live || configuration.role != 'SOURCE'"
                  small
                  :color="live && configuration.role == 'SOURCE' ? 'success' : 'primary'"
                  @click="updateBuySlider(0.75)"
                  >75%</v-btn
                ></v-col
              >
              <v-col
                ><v-btn
                  block
                  :outlined="!live || configuration.role != 'SOURCE'"
                  small
                  :color="live && configuration.role == 'SOURCE' ? 'success' : 'primary'"
                  @click="updateBuySlider(1)"
                  >100%</v-btn
                ></v-col
              >
            </v-row>
            <v-row dense>
              <v-col v-if="isDex(configuration.exchange) && configuration.role == 'SOURCE'">
                <v-btn block depressed small color="primary" @click="buySend()">Buy & Send</v-btn>
              </v-col>
              <v-col>
                <v-btn block depressed small color="success" @click="buy()">Buy</v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col>
            <v-text-field
              outlined
              dense
              hide-details
              class="mb-2"
              label="Price"
              v-model="priceSell"
              :append-outer-icon="roboSell ? 'mdi-robot-off-outline' : 'mdi-robot-outline'"
              @click:append-outer="roboSell = !roboSell"
            ></v-text-field>
            <v-text-field
              :disabled="roboSell"
              outlined
              dense
              hide-details
              class="mb-2"
              label="Amount"
              v-model="amountSell"
              :suffix="`~ ${addCommasAndFloor(amountSell * priceSell, 0)} $`"
            ></v-text-field>
            <v-slider
              :disabled="roboSell"
              hide-details
              :step="configuration.lotPrecision <= 6 ? precisionToDecimal(configuration.lotPrecision) : 0.000001"
              class="mb-2"
              v-model="amountSell"
              :max="maxSellableAmount"
            ></v-slider>
            <v-row dense class="mb-1">
              <v-col><v-btn block outlined small color="primary" @click="updateSellSlider(0.25)">25%</v-btn></v-col>
              <v-col><v-btn block outlined small color="primary" @click="updateSellSlider(0.5)">50%</v-btn></v-col>
              <v-col><v-btn block outlined small color="primary" @click="updateSellSlider(0.75)">75%</v-btn></v-col>
              <v-col><v-btn block outlined small color="primary" @click="updateSellSlider(1)">100%</v-btn></v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-btn block small depressed color="error" @click="sell()">{{
                  !roboSell ? "Sell" : "Robot sell"
                }}</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <BookTable
          :exchange="configuration.exchange"
          :symbol="configuration.symbol"
          :role="configuration.role"
          :tickPrecision="configuration.tickPrecision"
          :lotPrecision="configuration.lotPrecision"
          @populate-fields="populateFields"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="conversionDialog" max-width="500">
      <ConversionCard
        :conversionConfig="{
          currency: configuration.quoteCurrency,
          exchange: configuration.exchange,
        }"
        @close="closeConversionDialog()"
      />
    </v-dialog>
  </v-col>
</template>

<script>
import ConversionCard from "../ConversionCard.vue";
import BookTable from "../BookTable.vue";
import CandleChart from "../CandleChart.vue";

import trades from "../../../../mixins/trades";

import _ from "lodash";

export default {
  name: "DesktopTradeExchange",
  components: {
    ConversionCard,
    BookTable,
    CandleChart,
  },
  props: ["configuration"],
  mixins: [trades],
  data: () => ({
    conversionDialog: false,
    priceBuy: 0,
    amountBuy: 0,
    priceSell: 0,
    amountSell: 0,
    roboSell: false,
  }),
  methods: {
    updateBuySlider(percentage) {
      this.amountBuy = this.maxBuyableAmount * percentage;
      console.log("maxBuyableAmount" + this.maxBuyableAmount);
      // if (this.live == true) {
      //     this.priceBuy = this.$store.state.tradeWindow.maxBuyPrice;
      // }
    },
    updateSellSlider(percentage) {
      this.amountSell = this.maxSellableAmount * percentage;
    },
    buy() {
      var message = {
        target: "trade",
        action: "placeLimitOrder",
        exchange: this.configuration.exchange,
        market: this.configuration.symbol,
        side: "BUY",
        price: this.priceBuy,
        amount: this.amountBuy,
      };
      if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
        this.$store.state.connection.send(JSON.stringify(message));
      }
    },
    sell() {
      var message = {
        target: "trade",
        action: !this.roboSell ? "placeLimitOrder" : "placeRobotOrder",
        exchange: this.configuration.exchange,
        market: this.configuration.symbol,
        side: "SELL",
        price: this.priceSell,
        amount: this.amountSell,
      };
      if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
        this.$store.state.connection.send(JSON.stringify(message));
      }
    },
    buySend() {
      if (this.$store.state.depositAddress.address == null) return;

      var message = {
        target: "trade",
        action: "swapSendOrder",
        exchange: this.configuration.exchange,
        market: this.configuration.symbol,
        //           side: 'BUY',
        price: this.priceBuy,
        amount: this.amountBuy,
        recipient: this.$store.state.depositAddress.address,
      };
      if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
        this.$store.state.connection.send(JSON.stringify(message));
      }
    },
    openConversionDialog() {
      this.conversionDialog = true;
    },
    closeConversionDialog() {
      this.conversionDialog = false;
    },
    populateFields(entry) {
      //         if (entry.type == 'buy') {
      this.amountBuy = entry.volume;
      this.priceBuy = entry.price;
      //        }
      //       if (entry.type == 'sell') {
      this.amountSell = entry.volume;
      this.priceSell = entry.price;
      //      }
    },
  },
  computed: {
    live: function () {
      var opportunitiesKeys = this.$store.state.opportunities.map(function (opportunity) {
        return opportunity.key;
      });
      if (opportunitiesKeys.includes(this.$store.state.tradeWindow.key)) return true;
      return false;
    },
    baseCurrencyBalance: function () {
      var exchangeBalance = this.$store.state.balances[this.configuration.exchange];
      if (exchangeBalance === undefined) {
        return 0;
      }
      var balance = exchangeBalance[this.configuration.baseCurrency];
      if (balance === undefined) {
        return 0;
      }

      return balance;
    },
    quoteCurrencyBalance: function () {
      var exchangeBalance = this.$store.state.balances[this.configuration.exchange];
      if (exchangeBalance === undefined) {
        return 0;
      }
      var balance = exchangeBalance[this.configuration.quoteCurrency];
      if (balance === undefined) {
        return 0;
      }
      return balance;
    },
    maxBuyableAmount: function () {
      let maxBuyableUstd = this.quoteCurrencyBalance;

      if (this.live) {
        const liveData = this.updateLiveTrade(this.$store.state.opportunities);
        maxBuyableUstd = Math.min(this.quoteCurrencyBalance, liveData.maxBuyable / liveData.conversionRate);
      }

      return this.decimalFloor(maxBuyableUstd / this.priceBuy, Math.min(this.configuration.lotPrecision, 6)) || 0;
    },
    maxSellableAmount: function () {
      return (
        this.decimalFloor(
          this.baseCurrencyBalance,
          this.configuration.lotPrecision <= 6 ? this.configuration.lotPrecision : 6
        ) || 0
      );
    },
  },
  watch: {
    configuration: function () {
      Object.assign(this.$data, this.$options.data());
      this.priceBuy = this.configuration.firstAsk;
      this.priceSell = this.configuration.firstBid;
    },
    priceBuy: function () {
      this.debouncedPriceBuy();
    },
    priceSell: function () {
      this.debouncedPriceSell();
    },
  },
  mounted() {
    // this.priceBuy = this.$store.state.tradeWindow.maxBuyPrice;
    // this.priceSell = this.$store.state.tradeWindow.avgSellPrice;
    this.priceBuy = this.configuration.firstAsk;
    this.priceSell = this.configuration.firstBid;
  },
  created() {
    this.debouncedPriceBuy = _.debounce(() => {
      this.priceBuy = this.decimalCeil(this.priceBuy, this.configuration.tickPrecision);
    }, 500);

    this.debouncedPriceSell = _.debounce(() => {
      this.priceSell = this.decimalFloor(this.priceSell, this.configuration.tickPrecision);
    }, 500);
  },
};
</script>

<style scoped>
</style>