import Vue from 'vue'
import Router from 'vue-router'

import Desktop from '../components/views/Desktop.vue'
import Mobile from '../components/views/Mobile.vue'

import DesktopOpportunities from '../components/pages/desktop/DesktopOpportunities.vue'
import DesktopTrade from '../components/pages/desktop/DesktopTrade.vue'

import MobileOpportunities from '../components/pages/mobile/MobileOpportunities.vue'
import MobileWallet from '../components/pages/mobile/MobileWallet.vue'
import MobileTrade from '../components/pages/mobile/MobileTrade.vue'

Vue.use(Router);

export default new Router({
    // eslint-disable-next-line no-unused-vars
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
      },
  //  mode: 'history',
  //  history: createWebHistory(),
    routes: [
        {
            path: '/desktop',
            component: Desktop,
            children: [
                {
                    path: '',
                    redirect: 'opportunities',
                },
                {
                    path: 'opportunities',
                    component: DesktopOpportunities,
                },
                {
                    path: 'trade',
                    component: DesktopTrade,
                }
            ]
        },
        {
            path: '/mobile',
            component: Mobile,
            children: [
                {
                    path: '',
                    redirect: 'opportunities',
                },
                {
                    path: 'opportunities',
                    component: MobileOpportunities,
                },
                {
                    path: 'wallet',
                    component: MobileWallet,
                },
                {
                    path: 'trade',
                    component: MobileTrade,
                }
                
            ]
        }
    ]
});