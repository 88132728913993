<template>
<v-list two-line dense>
      <template v-for="(order, index) in orderList">
        <v-list-item :key="index">
            <!-- <v-list-item-avatar tile size="30">
                <v-chip class="float-right" x-small color="success">BUY</v-chip>
            </v-list-item-avatar> -->
            <v-list-item-content>
                <v-list-item-title class="mb-2" ><v-chip class="mr-2" :color="(order.side.toUpperCase() == 'BUY') ? 'success' : 'error'" x-small>{{order.side.toUpperCase()}}</v-chip>{{order.createdTime | dateTime}} | {{formatNumberForView(decimalFloor((order.filledQuantity > 0 && order.quantity > 0) ? (order.filledQuantity / order.quantity) * 100 : 0, 0))}} %<span v-if="history">| {{order.normalizedStatus}}</span></v-list-item-title>
                <v-list-item-subtitle class="text-subtitle1">Quantity: {{formatNumberForView(decimalFloor(order.quantity, lotPrecision))}} | Price: {{formatNumberForView(decimalFloor(order.price, tickPrecision))}} | Notional: {{formatNumberForView(decimalFloor(order.price * order.quantity, 2))}}</v-list-item-subtitle>
                <v-list-item-subtitle class="text-subtitle1">Filled: {{formatNumberForView(decimalFloor(order.filledQuantity, lotPrecision))}} | Filled Notional: {{formatNumberForView(decimalFloor(order.filledQuantity * order.avgFilledPrice, 2))}}</v-list-item-subtitle>
                <v-list-item-subtitle class="text-subtitle1">Avg price: {{formatNumberForView(decimalFloor(order.avgFilledPrice, tickPrecision))}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if="history == false">
                <v-icon @click="cancelOrder(order)">mdi-trash-can-outline</v-icon>
            </v-list-item-action>
        </v-list-item>
        <v-divider :key="index"  v-if="index < (Object.keys(orderList).length - 1)"></v-divider>
      </template>
</v-list>
</template>

<script>
export default {
    name: "MobileOrderList",
    props: ['exchange', 'baseCurrency', 'quoteCurrency', 'history', 'tickPrecision', 'lotPrecision'],
        computed: {
            orderList: function () {
            var ordersArray;
                if (this.history == true) {
                    ordersArray = this.$store.state.orderHistory;
                }
                else {
                    ordersArray = this.$store.state.openOrders[this.exchange];
                }
                if (ordersArray === undefined) return [];
                return ordersArray.filter(order => order.baseCurrency == this.baseCurrency && order.quoteCurrency == this.quoteCurrency && order.exchange == this.exchange);
            }
        },
    methods: {
        cancelOrder(order) {
            var message = {
                target: 'trade',
                action: 'cancelOrder',
                market: order.market,
                orderId: order.id,
                exchange: order.exchange, 
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        }
    }
}
</script>

<style scoped>

</style>