<template>
  <v-list>
    <template v-for="(balance, exchange, index) in orderedExchanges">
      <v-list-item :key="exchange">
        <v-list-item-avatar tile size="30">
          <v-img
            :src="'https://s2.coinmarketcap.com/static/img/exchanges/64x64/' + getExchangeId(exchange) + '.png'"
            @click="copy2FA(exchange)"
            class="pointer"
          ></v-img>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold"
            >{{ exchange
            }}<span class="float-right pointer" @click="openTransferDialog(exchange)"
              >{{ privacy ? "*****" : addCommasAndFloor(balance, 0) }} &dollar;</span
            ></v-list-item-title
          >
          <v-list-item-subtitle
            ><div v-if="!hideStatistics(exchange)">
              {{ statistics(exchange)[2] }}/{{ statistics(exchange)[1] }}/{{ statistics(exchange)[0] }}
            </div>
            <div v-else>&#8203;
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider :key="index" v-if="index < Object.keys(orderedExchanges).length - 1" inset></v-divider>
    </template>
  </v-list>
</template>

<script>
export default {
  name: "ExchangeList",
  data: () => ({
    privacy: false,
  }),
  methods: {
    hideStatistics(exchange) {
      switch (exchange) {
        case "TrustWallet":
        case "Trident":
          return true;
        default:
          return false;
      }
    },
    statistics(exchange) {
      if (this.$store.state.statistics.markets[exchange] === undefined) return [0, 0, 0];
      return this.$store.state.statistics.markets[exchange];
    },
    openTransferDialog(exchange) {
      this.$emit("open-transfer-dialog", exchange);
    },
    onStorageUpdate(event) {
      if (event.key === "privacy") {
        this.privacy = JSON.parse(localStorage.privacy);
      }
    },
  },
  computed: {
    orderedExchanges: function () {
      var exchanges = Object.assign({}, this.$store.state.balances);
      var keys = Object.keys(exchanges);

      var excludedKeys = ["TrustWallet", "Trident"];
      var includedKeys = [];

      // First, remove excluded keys from the keys array and add them to includedKeys
      excludedKeys.forEach(function (key) {
        if (keys.includes(key)) {
          keys.splice(keys.indexOf(key), 1);
          includedKeys.push(key);
        }
      });

      // Then, sort the remaining keys
      keys.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase(), undefined, { numeric: true }));

      // Finally, concatenate the includedKeys array to the beginning of the sorted keys array
      keys = includedKeys.concat(keys);

      // Create the result object using the sorted keys array
      var result = {};
      keys.forEach(function (key) {
        result[key] = exchanges[key] !== null ? exchanges[key]["USDT"] : 0;
      });

      return result;
    },
  },
  mounted() {
    if (localStorage.privacy) {
      this.privacy = JSON.parse(localStorage.privacy);
    }
    window.addEventListener("storage", this.onStorageUpdate);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onStorageUpdate);
  },
};
</script>

<style scoped>
</style>