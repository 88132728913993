<template>
    <v-col>
        <v-row>
            <v-col  class="px-3">
                <div class="text-subtitle-2">{{tradeWindow.source.exchange}}</div> 
                <v-radio-group dense hide-details class="mt-0 px-2" v-model="sourceChain">
                    <v-radio v-for="(chain, index) in tradeWindow.source.chains" :key="index" :value="chain.chain" :label="chain.chain + ' (' + addCommasAndFloor(chain.withdrawFee * tradeWindow.source.firstAsk * tradeWindow.source.conversionRate, 2) + '$)'" :disabled="chain.withdrawStatus == 'NOT_ALLOWED'"/>
                </v-radio-group>
            </v-col>
            <v-col class="col-2">
                <v-icon>mdi-arrow-right</v-icon>
            </v-col>
            <v-col class="px-3">
                <div class="text-subtitle-2">{{tradeWindow.destination.exchange}}</div> 
                <v-radio-group dense hide-details class="mt-0 px-2" v-model="destinationChain">
                    <v-radio v-for="(chain, index) in tradeWindow.destination.chains" :key="index" :value="chain.chain" :label="chain.chain" :disabled="chain.depositStatus == 'NOT_ALLOWED'" @click="getDepositAddress(tradeWindow.destination.exchange, tradeWindow.destination.baseCurrency, chain.chain)"/>
                </v-radio-group>
            </v-col>
        </v-row>
        <v-row dense class="pt-2">
            <v-col>
                <v-text-field hide-details dense class="mb-4" label="Address" v-model="depositAddress"></v-text-field>
                <v-text-field hide-details dense class="mb-4" label="Quantity" v-model="transferAmount"></v-text-field>
                <v-btn block depressed color="primary" @click="transfer()">Transfer</v-btn>
            </v-col>
        </v-row>
    </v-col>
</template>

<script>
export default {
    name: "TradeTransfer",
    data() { return {
            sourceChain: null,
            destinationChain: null,
            transferAmount: this.quantity,
        }
    },
    computed: {
        tradeWindow: function() {
            return this.$store.state.tradeWindow;
        },
        depositAddress: {      
            get() {
                var depositAddress = this.$store.state.depositAddress;
                var address = '';
                if (depositAddress === undefined) return address;
                if (depositAddress.exchange == this.tradeWindow.destination.exchange && depositAddress.chain == this.destinationChain && depositAddress.currency == this.tradeWindow.destination.baseCurrency) {
                    address = depositAddress.address;
                }
            return address;
            },
            set(value) {
                var newDepositAddress = {
                    exchange: this.tradeWindow.destination.exchange,
                    address: value,
                    chain: this.destinationChain,
                    currency: this.tradeWindow.source.baseCurrency,
                }
                this.$store.commit('updateDepositAddress', newDepositAddress);
            }        
        },
        quantity: function() {
            var balances = this.$store.state.balances;
            var sourceBalances = balances[this.tradeWindow.source.exchange];
            if (sourceBalances === undefined) return null;
            var currencyBalance = sourceBalances[this.tradeWindow.source.baseCurrency];
            if (currencyBalance === undefined) return null;
            return this.decimalFloor(currencyBalance, this.tradeWindow.source.lotPrecision);
        }
    },
    methods: {
        getDepositAddress(exchange, currency, chain) {
            var message = {
                target: 'wallet',
                action: 'getDepositAddress',
                exchange: exchange,
                currency: currency,
                chain: chain,
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        },
        transfer() {
            var message = {
                target: 'wallet',
                action: 'withdrawToAddress',
                exchange: this.tradeWindow.source.exchange,
                currency: this.tradeWindow.source.baseCurrency,
                chain: this.sourceChain,
                address: this.depositAddress,
                amount: this.transferAmount,
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        },
        autoselectChain() {
           if (this.tradeWindow.source.chains.length == 1) {
                this.sourceChain = this.tradeWindow.source.chains[0].chain;
            }
            else {
                this.sourceChain = null;
            }

            if (this.tradeWindow.destination.chains.length == 1) {
                this.destinationChain = this.tradeWindow.destination.chains[0].chain;
                this.getDepositAddress(this.tradeWindow.destination.exchange, this.tradeWindow.destination.baseCurrency, this.destinationChain);
            }
            else {
                this.destinationChain = null;
            }
        }
    },
    watch: {
        tradeWindow: function () {
            this.$store.commit('updateDepositAddress', {});
            this.autoselectChain();
            this.transferAmount = this.quantity;
        },
        quantity: function () {
            this.transferAmount = this.quantity;
        }
    },
    mounted() {
        this.$store.commit('updateDepositAddress', {});
        this.autoselectChain();
        this.transferAmount = this.quantity;
    }
}
</script>

<style scoped>

</style>