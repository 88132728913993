<template>
  <div>
    <MobileTradeWindow v-if="visible" />
     <v-alert v-else border="left" outlined prominent type="info">
            Please select a trade from the list.
    </v-alert>
  </div>
</template>

<script>
import MobileTradeWindow from '../components/mobile/MobileTradeWindow.vue';
export default {
    name: "MobileTrade",
    computed: {
        visible: function () {
            return (Object.keys(this.$store.state.tradeWindow).length === 0) ? false : true;
        }
    },
    components: { MobileTradeWindow }
}
</script>

<style scoped>

</style>