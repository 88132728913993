<template>
  <v-card>
        <v-card-text>
            <v-row class="px-5 pt-6">
                <v-col class="text-center justify-center align-center d-flex">
                <v-sheet>
                  <v-img width="56" :src="'https://s2.coinmarketcap.com/static/img/exchanges/64x64/' + getExchangeId(sourceExchange) + '.png'" ></v-img>
                  <div class="text-h6 font-weight-bold mt-3">{{sourceExchange}}<br />{{addCommasAndFloor(this.$store.state.balances[sourceExchange]['USDT'], 0)}} &dollar;</div>
                </v-sheet>
                </v-col>
                <v-col class="text-center justify-center align-center d-flex">
                <v-sheet>
                    <v-icon x-large>mdi-arrow-right-bold</v-icon>
                </v-sheet>
                </v-col>
                <v-col class="text-center justify-center align-center d-flex">
                <v-sheet v-if="destinationExchange != 'none'">
                  <v-img width="56" :src="'https://s2.coinmarketcap.com/static/img/exchanges/64x64/' + getExchangeId(destinationExchange) + '.png'"></v-img>
                  <div class="text-h6 font-weight-bold mt-3">{{destinationExchange}}<br />{{addCommasAndFloor(this.$store.state.balances[destinationExchange]['USDT'], 0)}} &dollar;</div>
                </v-sheet>
                </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-select outlined
                  :items="orderedExchanges"
                  label="Destination exchange"
                  v-model="destinationExchange"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-center justify-center align-center d-flex">
                <div class="text-h6 font-weight-bold"><v-edit-dialog>Amount: {{addCommas(amount)}}<template v-slot:input>
            <v-text-field
              label="Amount"
              single-line
              v-model="amount"
              type="number"
              hide-spin-buttons
              :max="sliderMax"
              @focus="resetAmount()"
            ></v-text-field>
          </template></v-edit-dialog> &dollar;</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-slider min="0" :max="sliderMax" v-model="amount">
            <!-- <template v-slot:append>
              <v-text-field
                v-model="amount"
                class="mt-0 pt-0"
                hide-details
                single-line
                type="number"
                style="width: 60px"
              ></v-text-field>
              </template> -->
                </v-slider>
              </v-col>
            </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4">
            <v-checkbox v-model="closeAfterConfirm" label="Close dialog after confirm"></v-checkbox>
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="close()">Cancel</v-btn>
            <v-btn color="primary" @click="confirm()" :disabled="amount == 0 || destinationExchange == 'none'">Confirm</v-btn>
        </v-card-actions>
      </v-card>
</template>

<script>
export default {
    name: "TransferCard",
    props: ['sourceExchange'],
    data: () => ({
      destinationExchange: 'none',
      amount: 0,
      closeAfterConfirm: true,
    }),
    methods: {
      confirm() {
        var message = {
          target: "wallet",
          action: "withdrawUsdt",
          exchange: this.sourceExchange,
          destExchange: this.destinationExchange,
          amount: this.amount,
        }

            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        if (this.closeAfterConfirm) {
          this.close();
        }
      },
      close() {
        Object.assign(this.$data, this.$options.data());
        this.$emit('close');
      },
      resetAmount() {
        this.amount = 0;
      }
    },
    computed: {
      orderedExchanges: function () {
        var exchanges = Object.assign({}, this.$store.state.balances);
        delete exchanges[this.sourceExchange];
        return Object.keys(exchanges).sort();
      },
      sliderMax: function () {
        return this.decimalFloor(this.$store.state.balances[this.sourceExchange]['USDT'], 0);
      }
    },
}
</script>

<style scoped>

</style>