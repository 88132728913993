<template>
  <div>
    <BalanceList />
    <v-divider></v-divider>  
    <ExchangeList @open-transfer-dialog="openTransferDialog" />
    <v-dialog v-model="transferDialog" fullscreen
        hide-overlay
        transition="dialog-bottom-transition">
      <TransferCard :sourceExchange="transferDialogExchange" @close="closeTransferDialog()" />
    </v-dialog>
  </div>
</template>

<script>
import BalanceList from '../components/BalanceList.vue';
import ExchangeList from '../components/ExchangeList.vue';
import TransferCard from '../components/TransferCard.vue';

export default {
    name: "MobileWallet",
    components: {
      BalanceList,
      ExchangeList,
      TransferCard,
    },
    methods: {
      openTransferDialog(exchange) {
        this.transferDialogExchange = exchange;
        this.transferDialog = true;
      },
      closeTransferDialog() {
        this.transferDialog = false;
      },
    },
    data: () => ({
      transferDialogExchange: 'none',
      transferDialog: false,
    }),
}
</script>

<style scoped>

</style>