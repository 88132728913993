<template>
    <div style="margin: -12px;">
            <v-tabs v-model="tab" grow>
                <v-tab value="source">{{tradeWindow.source.exchange}}<br />({{tradeWindow.source.baseCurrency}}/{{tradeWindow.source.quoteCurrency}})</v-tab>
                <v-tab value="destination">{{tradeWindow.destination.exchange}}<br />({{tradeWindow.destination.baseCurrency}}/{{tradeWindow.destination.quoteCurrency}})</v-tab>
                <v-tab value="transfer"><v-icon>mdi-bank-transfer-out</v-icon></v-tab>
            </v-tabs>
              <v-tabs-items v-model="tab" touchless>
                <v-tab-item :eager="true">
                  <MobileTradeExchange v-if="tab == 0" :configuration="tradeWindow.source" />
                </v-tab-item>
                <v-tab-item :eager="true">
                  <MobileTradeExchange  v-if="tab == 1" :configuration="tradeWindow.destination" />
                </v-tab-item>
                <v-tab-item :eager="true">
                  <TradeTransfer />
                  <!-- <v-divider></v-divider> -->
                  <!-- <TradeDetails /> -->
                </v-tab-item>
            </v-tabs-items>
    </div>
</template>

<script>
import MobileTradeExchange from './MobileTradeExchange.vue';
import TradeTransfer from '../commons/TradeTransfer.vue';
// import TradeDetails from '../commons/TradeDetails.vue';

export default {
    name: "MobileTradeWindow",
    components: {
      MobileTradeExchange,
      TradeTransfer,
      // TradeDetails,
    },
    data: () => ({
        tab: 0,
    }),
    methods: {
      subscribeStreamBook() {
        var message = {
          target: 'streaming',
          action: 'subscribe',
          channel: {
            coinName: this.tradeWindow.source.baseCurrency,
            buyFrom: this.tradeWindow.source.exchange,
            buyFromQuoteCurrency: this.tradeWindow.source.quoteCurrency,
            buyFromSymbol: this.tradeWindow.source.symbol,
            sellTo: this.tradeWindow.destination.exchange,
            sellToQuoteCurrency: this.tradeWindow.destination.quoteCurrency,
            sellToSymbol: this.tradeWindow.destination.symbol,
            }
        };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
      },
      unsubscribeStreamBook() {
        var message = {
            target: 'streaming',
            action: 'unsubscribe',
        };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
      },
    },
    computed: {
        tradeWindow: function() {
            return this.$store.state.tradeWindow;
        },
        connection: function() {
          return this.$store.state.connection;
        },
    },
    watch: {
        tradeWindow: function() {
          this.subscribeStreamBook();
        },
        connection: function() {
          if (this.connection.readyState == 1) {
            setTimeout(() => {
              this.subscribeStreamBook();
            }, 100);
          }
        }
    },
    mounted () {
      this.subscribeStreamBook();
    },
    beforeDestroy() {
        this.unsubscribeStreamBook();
    },
}
</script>

<style scoped>
* >>> .v-slide-group__prev--disabled {
    display: none !important;
}
</style>