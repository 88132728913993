<template>
  <v-card>
    <v-card-text>
            <v-row class="px-5 pt-6">
                <v-col class="text-center justify-center align-center d-flex">
                <v-sheet>
                  <div class="text-h6">{{opportunity.coinName}} configuration</div>
                </v-sheet>
                </v-col>
            </v-row>
<v-row>
  <v-col cols="6">
    <h3>From Exchange:</h3>
    <v-checkbox dense hide-details v-model="fromExchange" :label="opportunity.sourceExchange"></v-checkbox>
  </v-col>
  <v-col cols="6">
    <h3>To Exchange:</h3>
    <v-checkbox dense hide-details v-model="toExchange" :label="opportunity.destinationExchange"></v-checkbox>
  </v-col>
  <v-col cols="12">
    <h3>Settings</h3>
    <v-checkbox dense hide-details v-model="deflationary" label="Deflationary"></v-checkbox>
    <v-checkbox dense hide-details v-model="hidden" label="Hidden"></v-checkbox>
    <v-checkbox dense hide-details v-model="muted" label="Muted"></v-checkbox>
  </v-col>
    <v-col cols="12">
    <h3>Duration</h3>
    <v-radio-group dense hide-details v-model="temporary">
      <v-radio :disabled="!(hidden || muted)" label="Ever" value="0" selected></v-radio>
      <v-radio :disabled="!(hidden || muted)" label="24 hours" value="1"></v-radio>
    </v-radio-group>
  </v-col>
</v-row>
        </v-card-text>
        <v-card-actions class="pa-4">
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="close()">Cancel</v-btn>
            <v-btn color="primary" @click="confirm()">Confirm</v-btn>
        </v-card-actions>
  </v-card>
</template>

<script>
export default {
    name: "OpportunityConfigCard",
    props: ['opportunity'],
    data: () => ({
        fromExchange: false,
        toExchange: false,
        deflationary: false,
        hidden: false,
        muted: false,
        temporary: "0",
    }), 
    methods: {
        confirm() {
          var message = {
            currency: this.opportunity.coinName,
            sourceExchange: this.fromExchange ? this.opportunity.sourceExchange : "NA",
            destinationExchange: this.toExchange ? this.opportunity.destinationExchange : "NA",
            
            deflationary: this.deflationary,
            hidden: this.hidden,
            muted: this.muted,
            temporary: this.temporary == "0" ? false : true,

            target: 'opportunities',
            action: 'config',
          }
          if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
              this.$store.state.connection.send(JSON.stringify(message));
          }
          this.$emit('close');

        },
        close() {
            this.$emit('close');
        },
    },
    watch: {
      opportunity: function() {
        this.deflationary = this.opportunity.deflationary;
        this.hidden = this.opportunity.hidden;
        this.muted = this.opportunity.muted;
        this.temporary = '0';
        this.fromExchange = false;
        this.toExchange = false;
      }
    },
    mounted() {
        this.deflationary = this.opportunity.deflationary;
        this.hidden = this.opportunity.hidden;
        this.muted = this.opportunity.muted;
    }
}
</script>

<style scoped>

</style>