<template>
  <v-simple-table dense>
    <thead>
      <tr>
        <!-- <th>Exchange</th> -->
        <th>Date</th>
        <th>TransferID</th>
        <th>Currency</th>
        <th>Amount</th>
        <th>Fee</th>
        <th>Address</th>
        <th>Tx Hash</th>
        <th>Status</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="transfer in transferList" :key="transfer.transferId">
        <!-- <td>{{transfer.exchange}}</td> -->
        <td>{{ transfer.timestamp | dateTime }}</td>
        <td>{{ transfer.id }}</td>
        <td>{{ transfer.currency }}</td>
        <td class="text-right">{{ addCommas(transfer.amount) }}</td>
        <td class="text-right">{{ addCommas(transfer.fee) }}</td>
        <td>
          {{ transfer.address | truncate(12, 6, 4, ".....") }}
          <v-icon small @click="copy2Clipboard(transfer.address)">mdi-content-copy</v-icon>
        </td>

        <td>
          <a v-if="transfer.txLink" :href="transfer.txLink" target="_blank">
            {{ transfer.txHash | truncate(12, 6, 6, ".....") }}
          </a>
          <span v-else>
            {{ transfer.txHash | truncate(12, 6, 6, ".....") }}
          </span>
          <v-icon small @click="copy2Clipboard(transfer.txHash)"> mdi-content-copy </v-icon>
        </td>
        <td>{{ transfer.status }}</td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<script>
export default {
  name: "TransferTable",
  props: ["exchange", "currency", "type"],
  computed: {
    transferList: function () {
      var transfersArray;
      if (this.type == "deposit") {
        transfersArray = this.$store.state.depositHistory;
      } else {
        transfersArray = this.$store.state.withdrawHistory;
      }
      transfersArray = transfersArray.filter(
        (transfer) => transfer.currency == this.currency && transfer.exchangeString == this.exchange
      );
      return transfersArray.sort(function (x, y) {
        return y.timestamp - x.timestamp;
      });
    },
  },
};
</script>

<style scoped>
</style>