<template>
  <div>
        <router-view></router-view>
        <notifications classes="vue-notification-desktop" width="300" max="2" />
    </div>
</template>

<script>
export default {
    name: "Desktop",
}
</script>

<style scoped>
/* * >>> .vue-notification-group {
  margin-top: 65px;
} */

* >>> .vue-notification-desktop {
  margin-top: 5px;
  margin-right: 5px;
  /* margin-left: 10px;
  margin-bottom: 5px; */
  font-size: 14px;
  padding: 10px;
  color: white;
  background: #44A4FC;
  border-left: 5px solid #187FE7;
}

* >>> .success-notification {
    background: #68CD86;
    border-left-color: #42A85F;
  }

 * >>> .warn-notification {
    background: #ffb648;
    border-left-color: #f48a06;
  }

 * >>> .error-notification {
    background: #E54D42;
    border-left-color: #B82E24;
  }

</style>