<template>
  <div>
    <v-app-bar app :color="isProduction() ? 'primary' : 'amber'">
      <div class="text-h6 font-weight-bold white--text">ArbiCrypto</div>
      <v-spacer></v-spacer>
      <div v-if="isOpportunities">
        <v-icon
          color="white"
          :class="{ 'ml-2': true, 'grey--text': !filterMuted }"
          @click="filterMuted = !filterMuted"
          >mdi-filter-outline</v-icon
        >
        <v-icon
          class="ml-2"
          color="white"
          @click="mobileSocketsResetDialog = !mobileSocketsResetDialog"
          >mdi-connection</v-icon
        >
      </div>
      <div v-if="isWallet">
        <v-icon class="ml-2" color="white" @click="redistributeUsdt()"
          >mdi-bank-transfer</v-icon
        >
        <v-menu :close-on-content-click="false" :nudge-width="200">
          <template v-slot:activator="{ on, attrs }">
            <v-icon large class="ml-2" color="white" v-bind="attrs" v-on="on"
              >mdi-dots-vertical</v-icon
            >
          </template>
          <v-card>
            <v-card-title>Balance settings</v-card-title>
            <v-divider></v-divider>
            <v-list>
              <v-list-item>
                <v-list-item-action>
                  <v-switch
                    v-model="moveFunds"
                    @click="toggleMoveFunds()"
                  ></v-switch>
                </v-list-item-action>
                <v-list-item-title>Enable move funds</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
      <div v-if="isTrade">
        <v-icon color="white" :class="{'ml-2': true, 'grey--text': !usdtPrices}" @click="toggleUsdtPrices()"
          >mdi-currency-usd</v-icon
        >
        <v-icon
          class="ml-2"
          color="white"
          @click="openOpportunityConfigDialog()"
          v-if="tradeVisible"
          >mdi-cog-outline</v-icon
        >
        <v-icon class="ml-2" color="white" @click="swap()" v-if="tradeVisible"
          >mdi-swap-horizontal</v-icon
        >
        <v-icon class="ml-1" color="white" @click="pushCurrentTrade()"
          >mdi-cloud-upload-outline</v-icon
        >
        <v-icon
          class="ml-1"
          color="white"
          @click="mobileTradeAddDialog = !modbileTradeAddDialog"
          >mdi-cash-plus</v-icon
        >
        <v-icon
          class="ml-2"
          color="white"
          @click="mobileChartsDialog = !mobileChartsDialog"
          v-if="tradeVisible"
          >mdi-chart-timeline-variant</v-icon
        >
        <v-icon
          large
          class="ml-2"
          color="white"
          @click="drawer = !drawer"
          v-if="!drawer"
          >mdi-menu-open</v-icon
        >
        <v-icon
          large
          class="ml-2"
          color="white"
          @click="drawer = !drawer"
          v-else
          >mdi-menu-open mdi-rotate-180</v-icon
        >
      </div>
    </v-app-bar>
    <v-navigation-drawer app left v-model="drawer" width="300">
      <TradeList @changed="drawer = false" />
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <router-view :filterMuted="filterMuted"></router-view>
      </v-container>
    </v-main>
    <v-bottom-navigation
      :value="navigation"
      color="white"
      background-color="primary"
      grow
      app
    >
      <v-btn
        to="/mobile/opportunities"
        value="opportunities"
        color="white"
        text
      >
        <span>Opportunities</span>
        <v-icon>mdi-cash-multiple</v-icon>
      </v-btn>

      <v-btn to="/mobile/wallet" value="wallet" color="white" text>
        <span>Wallet</span>
        <v-icon>mdi-wallet-outline</v-icon>
      </v-btn>

      <v-btn to="/mobile/trade" value="trade" color="white" text>
        <span>Trade</span>
        <v-icon>mdi-swap-horizontal-bold</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <notifications
      classes="vue-notification-mobile"
      position="bottom center"
      width="300"
    />
    <v-dialog
      v-model="mobileChartsDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      eager
    >
      <MobileChartsCard
        v-if="mobileChartsCard"
        @close="mobileChartsDialog = false"
      />
    </v-dialog>
    <v-dialog
      v-model="mobileTradeAddDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <TradeCard @close="mobileTradeAddDialog = false" />
    </v-dialog>
    <v-dialog
      v-model="mobileSocketsResetDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <SocketsResetCard @close="mobileSocketsResetDialog = false" />
    </v-dialog>
    <v-dialog
      v-model="mobileSwapDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <SwapCard @close="mobileSwapDialog = false" />
    </v-dialog>
    <v-dialog
      v-model="opportunityConfigDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <OpportunityConfigCard :opportunity="opportunityConfigDialogOpportunity" @close="opportunityConfigDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import TradeList from "../pages/components/TradeList.vue";
import MobileChartsCard from "../pages/components/mobile/MobileChartsCard.vue";
import TradeCard from "../pages/components/TradeCard.vue";
import SocketsResetCard from "../pages/components/SocketsResetCard.vue";
import SwapCard from "../pages/components/SwapCard.vue";
import OpportunityConfigCard from "../pages/components/OpportunityConfigCard.vue";

import toggles from "../../mixins/toggles";
import trades from "../../mixins/trades";

export default {
  name: "Mobile",
  mixins: [toggles, trades],
  components: {
    TradeList,
    MobileChartsCard,
    TradeCard,
    SocketsResetCard,
    SwapCard,
    OpportunityConfigCard,
  },
  data: () => ({
    navigation: "opportunities",
    drawer: false,
    filterMuted: true,
    mobileChartsDialog: false,
    mobileChartsCard: false,
    mobileTradeAddDialog: false,
    mobileSocketsResetDialog: false,
    mobileSwapDialog: false,
    opportunityConfigDialog: false,
  }),
  computed: {
    isOpportunities: function () {
      return this.$route.path === "/mobile/opportunities";
    },
    isTrade: function () {
      return this.$route.path === "/mobile/trade";
    },
    isWallet: function () {
      return this.$route.path === "/mobile/wallet";
    },
    moveFunds: function () {
      return this.$store.state.moveFundsFlag;
    },
    tradeVisible: function () {
      return Object.keys(this.$store.state.tradeWindow).length === 0
        ? false
        : true;
    },
    swapResponse: function () {
      return this.$store.state.swapResponse;
    },
    usdtPrices: function () {
      return this.$store.state.usdtPricesFlag;
    },
  },
  methods: {
    redistributeUsdt() {
      var message = {
        target: "wallet",
        action: "redistributeUsdt",
        exchange: "defaultSource",
      };
      if (
        this.$store.state.connection != null &&
        this.$store.state.connection.readyState === WebSocket.OPEN
      ) {
        this.$store.state.connection.send(JSON.stringify(message));
      }
    },
    isProduction() {
      return process.env.NODE_ENV == "production" ? true : false;
    },
    openSocketsResetDialog() {
      this.socketsResetDialog = true;
    },
    closeSocketsResetDialog() {
      this.socketsResetDialog = false;
    },
    openOpportunityConfigDialog() {
      var liveTrade = this.$store.state.tradeWindow;

      this.opportunityConfigDialogOpportunity = {
        coinName: liveTrade.coinName,
        sourceExchange: liveTrade.source.exchange,
        destinationExchange: liveTrade.destination.exchange,
        deflationary: false,
        hidden: false,
        muted: false,
        temporary: "0",
      };
      this.opportunityConfigDialog = true;
    },
  },
  watch: {
    mobileChartsDialog: function () {
      if (this.mobileChartsDialog == true) {
        this.mobileChartsCard = true;
      }
      if (this.mobileChartsDialog == false) {
        setTimeout(() => {
          this.mobileChartsCard = false;
        }, 500);
      }
    },
    swapResponse: function () {
      if (this.swapResponse != null) {
        this.mobileSwapDialog = true;
      }
    },
  },
};
</script>

<style scoped>
.v-bottom-navigation {
  /* height: calc(56px + env( safe-area-inset-bottom )) !important; */
  height: calc(40px + env(safe-area-inset-bottom)) !important;
}
.v-bottom-navigation >>> .v-btn {
  padding-bottom: calc(env(safe-area-inset-bottom) - 16px) !important;
}

.v-main {
  padding-bottom: calc(40px + env(safe-area-inset-bottom)) !important;
}
* >>> .vue-notification-group {
  margin-bottom: calc(40px + env(safe-area-inset-bottom));
}

* >>> .vue-notification-mobile {
  margin-bottom: 5px;
  font-size: 14px;
  padding: 10px;
  color: white;
  background: #44a4fc;
  border-left: 5px solid #187fe7;
}

* >>> .success-notification {
  background: #68cd86;
  border-left-color: #42a85f;
}

* >>> .warn-notification {
  background: #ffb648;
  border-left-color: #f48a06;
}

* >>> .error-notification {
  background: #e54d42;
  border-left-color: #b82e24;
}
</style>