<template>
    <v-simple-table dense>
    <thead>
        <tr>
            <!-- <th>Exchange</th> -->
            <!-- <th>OrderID</th> -->
            <th>Date</th>
            <th>Market</th>
            <th>Side</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Notional</th>
            <th>Filled</th>
            <th>Filled %</th>
            <th>Filled Notional</th>
            <th v-if="history == true">Avg fill price</th>
            <th v-if="history == true">Status</th>
            <th v-if="history == false">Cancel</th>
        </tr>
    </thead>
    <tbody>
        <tr v-for="order in orderList" :key="order.orderId"> 
            <!-- <td>{{order.exchange}}</td> -->
            <td>{{order.createdTime | dateTime}}</td>
            <!-- <td>{{order.id}}</td> -->
            <td>{{order.baseCurrency}}/{{order.quoteCurrency}}</td>
            <td><v-chip :color="(order.side.toUpperCase() == 'BUY') ? 'success' : 'error'" x-small>{{order.side.toUpperCase()}}</v-chip></td>
            <td class="text-right">{{formatNumberForView(decimalFloor(order.quantity, lotPrecision))}}</td>
            <td class="text-right">{{formatNumberForView(decimalFloor(order.price, tickPrecision))}}</td>
            <td class="text-right">{{formatNumberForView(decimalFloor(order.price * order.quantity, 2))}}</td>
            <td class="text-right">{{formatNumberForView(decimalFloor(order.filledQuantity, lotPrecision))}}</td>
            <td class="text-right">{{formatNumberForView(decimalFloor((order.filledQuantity > 0 && order.quantity > 0) ? (order.filledQuantity / order.quantity) * 100 : 0, 0))}} %</td>
            <td class="text-right">{{formatNumberForView(decimalFloor(order.filledQuantity * order.avgFilledPrice, 2))}}</td>
            <td class="text-right" v-if="history == true">{{formatNumberForView(decimalFloor(order.avgFilledPrice, tickPrecision))}}</td>
            <td class="text-center" v-if="history == true">{{order.normalizedStatus}}</td>
            <td class="text-center" v-if="history == false"><v-icon small @click="cancelOrder(order)">mdi-trash-can-outline</v-icon></td>
        </tr>
    </tbody>
</v-simple-table>
</template>

<script>
export default {
    name: "OrderTable",
    props: ['exchange', 'baseCurrency', 'quoteCurrency', 'history', 'tickPrecision', 'lotPrecision'],
    computed: {
        orderList: function () {
        var ordersArray;
            if (this.history == true) {
                ordersArray = this.$store.state.orderHistory;
            }
            else {
                ordersArray = this.$store.state.openOrders[this.exchange];
            }
            if (ordersArray === undefined) return [];
            return ordersArray.filter(order => order.baseCurrency == this.baseCurrency && order.quoteCurrency == this.quoteCurrency && order.exchange == this.exchange);
        }
//         openOrders: function() {
//             var sourceExchangeOpenOrders = this.$store.state.openOrders[this.sourceExchange];
//             var destinationExchangeOpenOrders = this.$store.state.openOrders[this.destinationExchange];
//             var ordersArray = [];
//             if (sourceExchangeOpenOrders !== undefined) {
//                 ordersArray = sourceExchangeOpenOrders;
//             }
//             if (destinationExchangeOpenOrders !== undefined) {
//                 if (ordersArray.length > 0) {
//                     ordersArray.concat(destinationExchangeOpenOrders);
//                 }
//                 else {
//                     ordersArray = destinationExchangeOpenOrders;
//                 }
//             }
//  //          return ordersArray;
//            return ordersArray.filter(order => order.baseCurrency == this.baseCurrency && (order.quoteCurrency == this.sourceQuoteCurrency || order.quoteCurrency == this.destQuoteCurrency))
//         }

    },
    methods: {
        cancelOrder(order) {
            var message = {
                target: 'trade',
                action: 'cancelOrder',
                market: order.market,
                orderId: order.id,
                exchange: order.exchange, 
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        }
    }
}
</script>

<style scoped>

</style>