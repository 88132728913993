<template>
  <v-simple-table dense fixed-header class="book_table" height="540px">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-center">Price</th>
          <th class="text-center">Amount</th>
          <th class="text-center">Total</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(entry, index) in displayedBookEntries"
          :key="entry.price"
          :class="bookType + '_tr'"
          :style="{
            backgroundSize: calculateBackgroundSize(entry.price * entry.volume) + '% 100%',
          }"
          @click="populateTradeValues(bookEntries, index, 'n/a')"
        >
          <td class="text-right">
            {{ formatNumberForView(decimalRound(entry.price, tickPrecision)) }}
          </td>
          <td class="text-right">
            {{ reduceNumberSize(calculateCumulativeAmount(displayedBookEntries, entry.price), lotPrecision) }}
          </td>
          <td class="text-right">
            {{ reduceNumberSize(calculateCumulativeValue(displayedBookEntries, entry.price), 2) }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "MobileBookTable",
  props: ["exchange", "symbol", "tickPrecision", "lotPrecision", "bookType"],
  computed: {
    asksBook: function () {
      const exchangeBookConfig = this.$store.state.streamBook[this.bookConfiguration];
      if (!exchangeBookConfig || exchangeBookConfig.symbol !== this.symbol) {
        return [];
      }
      return exchangeBookConfig.asks;
    },
    bidsBook: function () {
      const exchangeBookConfig = this.$store.state.streamBook[this.bookConfiguration];
      if (!exchangeBookConfig || exchangeBookConfig.symbol !== this.symbol) {
        return [];
      }
      return exchangeBookConfig.bids;
    },
    displayedAsksBook: function () {
      const exchangeBookConfig = this.$store.state.streamBook[this.bookConfiguration];
      if (!exchangeBookConfig || exchangeBookConfig.symbol !== this.symbol) {
        return [];
      }
      if (!exchangeBookConfig.quoteIsUsdt && this.usdtPrices) {
        return exchangeBookConfig.asksConverted;
      }
      return exchangeBookConfig.asks;
    },
    displayedBidsBook: function () {
      const exchangeBookConfig = this.$store.state.streamBook[this.bookConfiguration];
      if (!exchangeBookConfig || exchangeBookConfig.symbol !== this.symbol) {
        return [];
      }
      if (!exchangeBookConfig.quoteIsUsdt && this.usdtPrices) {
        return exchangeBookConfig.bidsConverted;
      }
      return exchangeBookConfig.bids;
    },
    bookConfiguration: function () {
      return this.exchange + this.symbol;
    },
    bookEntries: function () {
      if (this.bookType == "asks") return this.asksBook;
      return this.bidsBook;
    },
    displayedBookEntries: function () {
      if (this.bookType == "asks") return this.displayedAsksBook;
      return this.displayedBidsBook;
    },
    usdtPrices: function () {
      return this.$store.state.usdtPricesFlag;
    },
  },
  methods: {
    calculateBackgroundSize(value) {
      let maxBookValue = 0;

      let book = this.displayedBookEntries;

      book.forEach((element) => {
        if (element.price * element.volume > maxBookValue) {
          maxBookValue = element.price * element.volume;
        }
      });
      return this.decimalFloor((value / maxBookValue) * 100, 0);
    },
    populateTradeValues(book, index, type) {
      let emitEntry = {};
      let entry = book[index];
      emitEntry.price = entry.price;
      emitEntry.volume = this.calculateCumulativeAmount(book, entry.price);
      emitEntry.type = type;
      this.$emit("populate-fields", emitEntry);
    },
    calculateCumulativeAmount(book, price) {
      var cumulative = 0;
      for (let i = 0; i < book.length; i++) {
        cumulative = cumulative + book[i].volume;
        if (book[i].price == price) {
          break;
        }
      }
      return cumulative;
    },
    calculateCumulativeValue(book, price) {
      var cumulative = 0;
      for (let i = 0; i < book.length; i++) {
        cumulative = cumulative + book[i].volume * book[i].price;
        if (book[i].price == price) {
          break;
        }
      }
      return cumulative;
    },
  },
};
</script>

<style scoped>
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding: 0px !important;
}

.v-data-table--dense > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table--dense > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 20px;
}

.asks_tr {
  background-image: linear-gradient(
    to right,
    rgba(249, 174, 174, 1) 0%,
    rgba(249, 174, 174, 1) 100%
  ); /* your gradient */
  background-repeat: no-repeat;
  background-attachment: fixed;
}

.bids_tr {
  background-image: linear-gradient(
    to right,
    rgba(174, 249, 174, 1) 0%,
    rgba(174, 249, 174, 1) 100%
  ); /* your gradient */
  background-repeat: no-repeat;
  background-attachment: fixed;
}
</style>