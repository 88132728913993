<template>
    <v-row class="text-center">
    <v-col v-if="!updating" cols="12" class="pb-0">
      <v-alert class="mb-0" border="left" outlined prominent type="error">
          Opportunities not updating correctly.
      </v-alert>
    </v-col>
      <v-col cols="12" v-if="opportunities.length < 1">
        <v-alert border="left" outlined prominent type="info">
          No opportunities to show.
      </v-alert>
      </v-col>
      <v-col cols="12" v-else>
        <v-sheet rounded="lg" v-for="opportunity in opportunities" :key="opportunity.coin" class="my-2 pa-3" elevation="2" :class="getRowClass(opportunity)">
          <div style="text-align: left;">
        <div style="font-weight: bold; font-size: 20px; float: left; margin-top: 8px;"><img
              :src="
                'https://s2.coinmarketcap.com/static/img/coins/32x32/' +
                opportunity.cmcId +
                '.png'
              " style="width: 20px; height: 20px;"
            /> {{opportunity.coinName}} <v-icon v-if="opportunity.deflationary" small color="error">mdi-arrow-bottom-right-thick</v-icon> - {{addCommasAndFloor(opportunity.details.actualProfit, 0)}}&dollar; ({{addCommasAndFloor(opportunity.details.actualProfitPercent, 1)}}&percnt;)                      
            <!-- <v-icon
              v-if="!hiddenCoins.includes(opportunity.coinName+opportunity.buyFrom+opportunity.sellTo)"
              @click="hide(opportunity.coinName+opportunity.buyFrom+opportunity.sellTo)"
              >mdi-eye-outline</v-icon
            >
            <v-icon
              v-if="hiddenCoins.includes(opportunity.coinName+opportunity.buyFrom+opportunity.sellTo)"
              @click="unHide(opportunity.coinName+opportunity.buyFrom+opportunity.sellTo)"
              >mdi-eye-off-outline</v-icon
            > -->
            </div>

            <div class="float-right">
            <div class="mr-3 mt-2 d-inline-block" @click="addToTradeList(opportunity)">
              <v-chip
              v-if="opportunity.details.cap == 'BUY'"
              color="green"
              text-color="white"
              small
              >{{ opportunity.details.cap }}</v-chip
            >
            <v-chip
              v-if="opportunity.details.cap == 'SELL'"
              color="red"
              text-color="white"
              small
              >{{ opportunity.details.cap }}</v-chip
            >
            </div>
            <div class="fmr-3 mt-2 d-inline-block">
              <v-menu min-width="200" offset-x left>
              <template v-slot:activator="{ on, attrs }">
                 <v-icon v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
              </template>
              <v-card>
              <div class="pa-3">
                Last update: {{ addCommasAndFloor(opportunity.lastUpdate / 1000, 0) }} s
              </div>
              <v-divider></v-divider>
              <v-list dense>

                <v-list-item @click="openOpportunityConfigDialog(opportunity)">
                  <v-list-item-icon>
                  <v-icon>mdi-cog-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Configuration</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <!-- <v-list-item @click="toggleHide(opportunity)">
                  <v-list-item-icon>
                  <v-icon>mdi-monitor-off</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Hide</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="opportunity.muted" @click="toggleMute(opportunity)">
                  <v-list-item-icon>
                  <v-icon>mdi-volume-high</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Unmute</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-else @click="toggleMute(opportunity)">
                  <v-list-item-icon>
                  <v-icon>mdi-volume-variant-off</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Mute</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                  <v-list-item v-if="!opportunity.deflationary" @click="toggleDeflationary(opportunity)">
                  <v-list-item-icon>
                  <v-icon>mdi-arrow-bottom-right-thick</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Set Deflationary</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-else @click="toggleDeflationary(opportunity)">
                  <v-list-item-icon>
                  <v-icon>mdi-arrow-top-right-thick</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Unset Deflationary</v-list-item-title>
                  </v-list-item-content>
                </v-list-item> -->
    
              </v-list>
              <!-- <v-divider></v-divider>
              <div class="text-center pa-3">
                <v-rating
                dense 
                background-color="orange lighten-3"
                color="orange"></v-rating>
              </div> -->
              </v-card>
            </v-menu>
            </div>
            </div>
            <!-- <div class="float-right mr-3 mt-2" @click="toggleHide(opportunity)">
                <v-icon
              v-if="opportunity.hidden"
              >mdi-monitor-off</v-icon
            >
            <v-icon
              v-else
              >mdi-monitor</v-icon
            >
            </div>
            <div class="float-right mr-3 mt-2" @click="toggleMute(opportunity)">
            <v-icon
              v-if="opportunity.muted"
              
              >mdi-volume-variant-off</v-icon
            >
            <v-icon
              v-else
              >mdi-volume-high</v-icon
            >
            </div> -->
          <div style="clear: both;">Buy from: {{opportunity.sourceExchange}} ({{opportunity.sourceQuote}}) - Fees: {{addCommasAndFloor(opportunity.withdrawFee, 2)}}&dollar;</div>
          <div style="clear: both;">Sell to: {{opportunity.destinationExchange}} ({{opportunity.destinationQuote}})</div>
          <div>Avg buy: {{addCommasAndFloor(opportunity.details.avgBuyPrice, 6)}}&dollar; - Avg sell: {{addCommasAndFloor(opportunity.details.avgSellPrice, 6)}}&dollar;</div>
          <div style="font-weight: bold;">Max buyable: {{addCommasAndFloor(opportunity.details.maxBuyable, 0)}}&dollar; - Max profit: {{addCommasAndFloor(opportunity.details.maxProfit, 0)}}&dollar;</div>
          </div>
        </v-sheet>
      </v-col>
      <v-col cols="12">
         <v-dialog v-model="opportunityConfigDialog" fullscreen
        hide-overlay
        transition="dialog-bottom-transition" eager>
          <OpportunityConfigCard :opportunity="opportunityConfigDialogOpportunity" @close="closeOpportunityConfigDialog()" v-if="opportunityConfigDialogOpportunity"  />
        </v-dialog>
      </v-col>
    </v-row>
</template>

<script>
import toggles from '../../../mixins/toggles';
import trades from '../../../mixins/trades';

import OpportunityConfigCard from '../components/OpportunityConfigCard.vue';  


export default {
  name: "MobileOpportunities",
  mixins: [toggles, trades],
  props: ['filterMuted'],
  components: {
    OpportunityConfigCard
  },
  computed: {
    opportunities: function() {
        if (this.filterMuted) {
          return this.$store.state.opportunities.filter(opportunity => !opportunity.muted || opportunity.details.actualProfit > 300);
        }
        return this.$store.state.opportunities;
    },
    updating: function() {
        if ((this.nowTimestamp - this.$store.state.opportunitiesLastUpdate) > 10000) {
        return false;
      }
      return true;
      }
  },
  data: () => ({
    nowTimestamp: 0,
 //   socketsResetDialog: false,
    opportunityConfigDialog: false,
  }),
  methods: {
    addToTradeList(trade) { 
              this.$store.commit("updateTradeWindow", {});

        var message = {
          target: "opportunities",
          action: "getTrade",
          currency: trade.coinName,
          source: trade.sourceExchange + '|' + trade.sourceSymbol,
          destination: trade.destinationExchange + '|' + trade.destinationSymbol,
          mode: "pull",
        }

            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
  //    this.pushTrade(trade);
      this.$router.push({ path: '/mobile/trade' })
    },
    getRowClass(item) {
      let classes = "";
      if (item.muted) classes += "opaque ";
      if (item.lastUpdate > 300000 && !(item.sourceExchange.includes('OneInch') || item.destinationExchange.includes('OneInch'))) classes += "oldRefresh ";
      return classes.slice(0, -1);
    },
    openOpportunityConfigDialog(opportunity) {
       this.opportunityConfigDialogOpportunity = opportunity;
      this.opportunityConfigDialog = true;
     
    },
    closeOpportunityConfigDialog() {
      this.opportunityConfigDialog = false;
    },
    // openSocketsResetDialog() {
    //   this.socketsResetDialog = true;
    // },
    // closeSocketsResetDialog() {
    //   this.socketsResetDialog = false;
    // },
  },
  created() {
    setInterval(() => {
        this.nowTimestamp = Date.now();
    }, 5000)
  },
};
</script>

<style scoped>
.coinName {
  font-weight: bold;
  line-height: 16px;
}

a:link, a:visited, a:active {
  text-decoration: none;
  color: black;
}

* >>> .opaque {
  opacity: 0.2 !important;
}

* >>> .oldRefresh {
  background-color: #fdffcd !important;
}

</style>
