export default {
    methods: {
        toggleDeflationary(item) {
            var message = {
              target: "opportunities",
              action: "setDeflationary",
              currency: item.coinName,
              buyFrom: item.sourceExchange,
              sellTo: item.destinationExchange,
              deflationary: !item.deflationary,
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
              this.$store.state.connection.send(JSON.stringify(message));
          }
          },
          toggleHide(item) {
            var message = {
              target: "opportunities",
              action: "hideCoin",
              currency: item.coinName,
              buyFrom: item.sourceExchange,
              sellTo: item.destinationExchange,
              hide: !item.hidden,
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
              this.$store.state.connection.send(JSON.stringify(message));
          }
          },
          toggleMute(item) {
            var message = {
              target: "opportunities",
              action: "muteCoin",
              currency: item.coinName,
              buyFrom: item.sourceExchange,
              sellTo: item.destinationExchange,
              mute: !item.muted,
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
              this.$store.state.connection.send(JSON.stringify(message));
          }
          },
          toggleMoveFunds() {
            this.$store.commit('updateMoveFundsFlag', !this.moveFunds);
            var message = {
                target: "wallet",
                action: "toggleMoveFunds",
                exchange: "defaultSource",
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
              this.$store.state.connection.send(JSON.stringify(message));
          }
            },
          toggleUsdtPrices() {
              this.$store.commit('updateUsdtPricesFlag',!this.usdtPrices);
          }
    }
}