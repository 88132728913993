<template>
  <div>
    <v-app-bar
      app
      :color="isProduction() ? 'primary' : 'amber'"
      dark
      clipped-left
    >
      <div class="d-flex align-center">
        <h2 class="d-none d-sm-flex">ArbiCrypto / Trade</h2>
      </div>

      <v-spacer></v-spacer>
      <div class="font-weight-light mr-16">
                <v-icon :class="{'mr-5': true, 'grey--text': !usdtPrices}" @click="toggleUsdtPrices()"
          >mdi-currency-usd</v-icon
        >
        <v-icon class="mr-5" @click="openOpportunityConfigDialog()"
          >mdi-cog-outline</v-icon
        >
        <v-icon class="mr-2" @click="swap()">mdi-swap-horizontal</v-icon>
        <v-icon class="mr-2" @click="openTradeDialog()">mdi-cash-plus</v-icon>
        <v-icon class="mr-5" @click="pushCurrentTrade()"
          >mdi-cloud-upload-outline</v-icon
        >
      </div>
      <div class="text-h6 min-width-200 text-right">
        <v-menu :close-on-content-click="false" :nudge-width="150">
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on"
              >Balance: {{ privacy ? "*****" : addCommasAndFloor(totalBalance, 0) }} &dollar;</span
            >
          </template>

          <v-card>
            <BalanceList />
          </v-card>
        </v-menu>
      </div>
      <div>
        <v-icon large class="ml-3" @click="drawer = !drawer" v-if="!drawer"
          >mdi-menu-open</v-icon
        >
        <v-icon large class="ml-3" @click="drawer = !drawer" v-else
          >mdi-menu-open mdi-rotate-180</v-icon
        >
      </div>
    </v-app-bar>
    <v-navigation-drawer clipped app left v-model="drawer" width="300">
      <TradeList />
    </v-navigation-drawer>
    <v-main>
      <v-container fluid>
        <DesktopTradeWindow v-if="visible" />
        <v-alert v-else border="left" outlined prominent type="info">
          Please select a trade from the list.
        </v-alert>
      </v-container>
    </v-main>
    <v-dialog v-model="tradeDialog" max-width="500">
      <TradeCard @close="closeTradeDialog()" />
    </v-dialog>
    <v-dialog v-model="opportunityConfigDialog" max-width="500">
      <OpportunityConfigCard
        :opportunity="opportunityConfigDialogOpportunity"
        @close="closeOpportunityConfigDialog()"
      />
    </v-dialog>
  </div>
</template>

<script>
import BalanceList from "../components/BalanceList.vue";
import TradeList from "../components/TradeList.vue";
import DesktopTradeWindow from "../components/desktop/DekstopTradeWindow.vue";
import TradeCard from "../components/TradeCard.vue";
import OpportunityConfigCard from "../components/OpportunityConfigCard.vue";

import toggles from '../../../mixins/toggles';
import trades from "../../../mixins/trades";
import balance from "../../../mixins/balance";

export default {
  name: "DekstopTrade",
  components: {
    BalanceList,
    TradeList,
    DesktopTradeWindow,
    TradeCard,
    OpportunityConfigCard,
  },
  mixins: [toggles, trades, balance],
  data: () => ({
    drawer: true,
    balanceCard: false,
    privacy: false,
    tradeDialog: false,
    opportunityConfigDialog: false,
  }),
  methods: {
    isProduction() {
      return process.env.NODE_ENV == "production" ? true : false;
    },
    onStorageUpdate(event) {
      if (event.key === "privacy") {
        this.privacy = JSON.parse(localStorage.privacy);
      }
    },
    openTradeDialog() {
      this.tradeDialog = true;
    },
    closeTradeDialog() {
      this.tradeDialog = false;
    },
    openOpportunityConfigDialog() {
      var liveTrade = this.$store.state.tradeWindow;

      this.opportunityConfigDialogOpportunity = {
        coinName: liveTrade.coinName,
        sourceExchange: liveTrade.source.exchange,
        destinationExchange: liveTrade.destination.exchange,
        deflationary: false,
        hidden: false,
        muted: false,
        temporary: "0",
      };
      this.opportunityConfigDialog = true;
    },
    closeOpportunityConfigDialog() {
      this.opportunityConfigDialog = false;
    },
  },
  computed: {
    // totalBalance: function () {
    //   var totalUsdtBalance = 0;
    //   for (var i in this.$store.state.balances) {
    //     if (this.$store.state.balances[i] === null) continue;
    //     totalUsdtBalance += parseFloat(this.$store.state.balances[i]["USDT"]);
    //   }
    //   return this.addCommasAndFloor(totalUsdtBalance, 0);
    // },
    usdtPrices: function () {
      return this.$store.state.usdtPricesFlag;
    },
    visible: function () {
      return Object.keys(this.$store.state.tradeWindow).length === 0
        ? false
        : true;
    },
  },
  mounted() {
    if (localStorage.privacy) {
      this.privacy = JSON.parse(localStorage.privacy);
    }
    window.addEventListener("storage", this.onStorageUpdate);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.onStorageUpdate);
  },
};
</script>

<style scoped>
</style>