import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './plugins/router'
import {store} from './plugins/vuex'
import notifications from './plugins/notifications'
import CryptoJS from 'crypto-js';

import numbers from './mixins/numbers';
import exchangeLinks from './mixins/exchangeLinks';
import clipboard from './mixins/clipboard';

Vue.config.productionTip = false;

Vue.filter('dateTime', function(value) {
  if (value) {
    var date = new Date(value);
    return date.toLocaleString('it-IT');
  }
});

Vue.filter('truncate', function (value, size, prefixSize, suffixSize, mask) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }

  var substitutionString = '';
  if (prefixSize > 0) {
    substitutionString = value.substr(0, prefixSize);
  }

  substitutionString = substitutionString + mask;

  if (suffixSize > 0) {
    substitutionString = substitutionString + value.substr(value.length - suffixSize);
  }

  return substitutionString;
});

Vue.mixin({
  mixins: [numbers, exchangeLinks, clipboard],
  methods: {
      getCoinId(coin) {
        const ids = {
          BTC: 1,
          ETH: 1027,
          USDC: 3408,
        }
        return ids[coin];
      },
      getDecimalPositions(currency) {
        const configuration = {
            USDT: 2,
            USDC: 2,
            USD: 2,
            BTC: 6,
            ETH: 6,
        };

        var decimals = configuration[currency];

        if (decimals === undefined) return 6;
        
        return decimals;
    },
    swap() {
      let trade = JSON.parse(JSON.stringify(this.$store.state.tradeWindow));
      let oldSource = trade.source;
      oldSource.role = 'DESTINATION';
      trade.source = trade.destination;
      trade.source.role = 'SOURCE';
      trade.destination = oldSource;
      let word = trade.coinName + trade.source.exchange + trade.source.quoteCurrency + trade.destination.exchange + trade.destination.quoteCurrency;
      trade.key = CryptoJS.MD5(word).toString().substring(0, 8).toUpperCase();

  
      var tradeList = [];
      if (localStorage.tradeList) {
        tradeList = JSON.parse(localStorage.tradeList);
      }

      var match = tradeList.find(tradeElement => tradeElement.key == trade.key);
      if (match !== undefined) {
        tradeList[tradeList.indexOf(match)] = trade;
    //   tradeList.splice(tradeList.indexOf(match), 1);
      }
      else{
        tradeList.unshift(trade);
      }
      
      localStorage.tradeList = JSON.stringify(tradeList);

      window.dispatchEvent( new StorageEvent('storage', {
        key: 'tradeList',
      }) );
    }
  }
});

new Vue({
  vuetify,
  router,
  store,
  notifications,
  render: h => h(App),
}).$mount('#app');

