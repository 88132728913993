<template>
  <v-list dense three-line class="scrollbox">
      <v-list-item v-for="(notification, index) in this.$store.state.notifications" :key="index">
            <v-list-item-avatar>
              <v-icon v-text="getIcon(notification.status)" :color="notification.status" large></v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-title>{{notification.status.toUpperCase()}}</v-list-item-title>
                <v-list-item-subtitle>{{notification.message}}</v-list-item-subtitle>
            </v-list-item-content>
      </v-list-item>
  </v-list>
</template>

<script>
export default {
    name: "NotificationList",
    // data: () => ({
    //     notificationList: [],
    // }),
    methods: {
        onStorageUpdate(event) {
            if (event.key === 'notifications') {
                this.notificationList = JSON.parse(localStorage.notifications);
            }
        },
        getIcon(type) {
            const icons = {
                success: 'mdi-check-circle-outline',
                error: 'mdi-alert-octagon-outline',
                info: 'mdi-information-outline',
                warning: 'mdi-alert-outline',
            }
            return icons[type.toLowerCase()];
        },
    },
}
</script>

<style scoped>
.scrollbox {
    overflow-y: scroll;
    max-height: 500px;
    width: 350px;
}
</style>