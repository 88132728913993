export default {
    computed: {
      totalBalance() {
        var totalUsdtBalance = 0;
        for (var i in this.$store.state.balances) {
          if (this.$store.state.balances[i] === null) continue;
        
            const count = this.$store.state.exchangesFrontend.find(exchange => exchange.name === i).balanceCount || false;
            if (count)  {
                totalUsdtBalance += parseFloat(this.$store.state.balances[i]['USDT']);
            }  
        }
  //      return this.addCommasAndFloor(totalUsdtBalance, 0);
        return totalUsdtBalance;
      }
    }
}