<template>
    <div class="pa-3" :id="'chartContainer'+ role"></div>
</template>

<script>
import { createChart } from 'lightweight-charts';

export default {
    name: "CandleChart",
    props: ['role', 'interval', 'type'],
    data: () => ({
        candlestickSeries: null,
        chart: null,
        intervalFn: null,
    }),
    computed: {
        tradeWindow: function() {
            return this.$store.state.tradeWindow;
        },
        candles: function () {
            return this.$store.state.candles;
        },
        candlesVersion: function() {
            return this.$store.state.candlesVersion;
        },
        key: function () {
            let configuration = this.tradeWindow[this.role];
            return configuration.exchange + configuration.symbol + this.interval;
        },
        configuration: function() {
            return this.tradeWindow[this.role];
        }
    },
    methods: {
        chartInitialization() {
            let chartHeight = (this.type == 'mobile') ? 250 : 350; 
            this.chart = createChart(document.getElementById('chartContainer' + this.role), { width: 0, height: chartHeight});
            this.chart.applyOptions({ timeScale: {fixRightEdge: true, fixLeftEdge: true, timeVisible: true}});
            this.candlestickSeries = this.chart.addCandlestickSeries();
        },
        chartUpdate() {
            this.candlestickSeries.setData(this.getCandles());
        },
        chartSwitch() {
            this.candlestickSeries.applyOptions({priceFormat: {type: 'price', minMove: this.precisionToDecimal(this.configuration.tickPrecision), precision: this.configuration.tickPrecision}}); // this.precisionToDecimal(this.configuration.tickPrecision)}}); //, precision: this.configuration.tickPrecision}});
            
            var candles = this.getCandles();
        //    alert (this.getCandles());

            this.candlestickSeries.setData(this.getCandles());
            
            var timestamp = Math.floor(Date.now() / 1000);
            var start = timestamp - 3600;

            if (this.chart !== null && candles !== null && candles.length > 1 ) {
                var range = {from: start, to: timestamp};
                this.chart.timeScale().setVisibleRange(range);
                this.chart.timeScale().resetTimeScale();
            }
            
 
        },
        getCandles() {
           var candles = [];
            if (this.candles[this.key] !== undefined) {
                candles = this.candles[this.key];
            }
            else {
                return [];
            }
            
            var ohlcvArray = candles.map(function(candle) {
                return { time: candle.timestamp / 1000, open: candle.open, high: candle.high, low: candle.low, close: candle.close};
            });

            ohlcvArray.sort((a, b) => a.time - b.time);
            return ohlcvArray; 
        },
        retrieveCandles() {
            let configuration = this.tradeWindow[this.role];
            let message = {
                target: 'market',
                action: 'getCandles',
                exchange: configuration.exchange,
                symbol: configuration.symbol,
                interval: this.interval,
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        }
    },
    mounted() {
        setTimeout(() => {
            this.chartInitialization(); 
            this.retrieveCandles();
            this.chartSwitch();
        }, 300);
       
       this.intervalFn = setInterval(() => {
            this.retrieveCandles();
        }, 5000);
    },
    beforeDestroy() {
        clearInterval(this.intervalFn);
    },
    watch: {
        candlesVersion: function() {
            this.chartUpdate();
        },
        tradeWindow: function() {
            this.$store.commit('resetCandles');
            this.retrieveCandles();
      //      this.chartInitialization();
     //       this.chartSwitch();
        }
    }
}
</script>

<style scoped>

</style>