<template>
  <v-list>
    <v-list-item>
        <v-list-item-content>
            <v-list-item-title class="text-subtitle-1">Balance<span class="float-right text-h5 font-weight-bold">{{addCommasAndFloor(totalBalance, 0)}} &dollar;</span></v-list-item-title>
        </v-list-item-content>
    </v-list-item>
    <v-list-item>
        <v-list-item-content>
            <v-list-item-title class="text-subtitle-1">Ongoing trades<span class="float-right text-h6" :class="tradeDeltaColor" @click="resetDelta()">{{(tradeDelta > 0 ? '+' : '') + addCommasAndFloor(tradeDelta, 0)}} &dollar;</span></v-list-item-title>
        </v-list-item-content>
     </v-list-item>
  </v-list>
</template>

<script>
import balance from "../../../mixins/balance";

export default {
    name: "BalanceList",
    mixins: [balance],
    methods: {
      resetDelta() {
         localStorage.tradeDeltaBase = this.totalBalance;
         this.tradeDeltaBase = this.totalBalance;
      },
      onStorageUpdate(event) {
            if (event.key === 'tradeDeltaBase') {
                this.tradeDeltaBase = JSON.parse(localStorage.tradeDeltaBase);
            }
        },
    },
    mounted() {
        if (localStorage.tradeDeltaBase) {
            this.tradeDeltaBase = JSON.parse(localStorage.tradeDeltaBase);
        }
        window.addEventListener("storage", this.onStorageUpdate);
    },
    beforeDestroy() {
        window.removeEventListener("storage", this.onStorageUpdate);
    },
    data: () => ({
        tradeDeltaBase: 0,
    }),
    computed: {
        // totalBalance: function() {
        //     var totalUsdtBalance = 0;
        //         for (var i in this.$store.state.balances) {
        //           if (this.$store.state.balances[i] === null) continue;
        //             totalUsdtBalance += parseFloat(this.$store.state.balances[i]['USDT']);
        //     }
        //     return totalUsdtBalance;
        // },
        tradeDelta: function() {
            return this.totalBalance - this.tradeDeltaBase;
        },
        tradeDeltaColor: function() {
            if (this.tradeDelta > 0) {
                return 'green--text';
            }
            if (this.tradeDelta < 0) {
                return 'red--text';
            }
            return '';
        }
    }
}
</script>

<style scoped>

</style>