import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
      connection: null,
      notifications: [],
      tradeWindow: {},
      balances: {},
      openOrders: {},
      opportunities: [],
      opportunitiesLastUpdate: [],
      orderHistory: [],
      withdrawHistory: [],
      depositHistory: [],
      candles: {},
      candlesVersion: 0,
      statistics: {},
      totps: {},
      depositAddress: {},
      streamBook: {},
      getBooksResponse: {},
      moveFundsFlag: false,
      swapResponse: {},
      exchangesFrontend: [],
      usdtPricesFlag: true,
      exchangeConfiguration: {},
    },
    mutations: {
      updateTradeWindow (state, trade) {
        state.tradeWindow = trade;
      },
      updateConnection (state, connection) {
        state.connection = connection;
      },
      updateNotifications (state, notifications) {
        state.notifications = notifications;
      },
      updateMoveFundsFlag (state, moveFundsFlag) {
        state.statistics.moveFundsFlag = moveFundsFlag;
      },
      updateUsdtPricesFlag (state, usdtPricesFlag) {
        state.usdtPricesFlag = usdtPricesFlag;
      },
      updateDepositAddress (state, depositAddress) {
        state.depositAddress = depositAddress;
      },
      resetCandles (state) {
        state.candles = {};
      },
      resetGetBookResponse (state) {
        state.getBooksResponse = {};
      },
      updateFromSocket(state, message) {
        switch (message.action) {
          case 'opportunities':
            state.opportunities = message.data.list;
            state.opportunitiesLastUpdate = message.data.timestamp;
            break;
          case 'balances':
            state.balances = message.data;
            break;
          case 'statistics':
            state.statistics = message.data;
            break;
          case 'totps':
            state.totps = message.data;
            break;
          case 'orderHistory':
            state.orderHistory = message.data;
            break;
          case 'openOrders':
            state.openOrders = message.data;
            break;
          case 'depositAddress':
            state.depositAddress = message.data;
            break;
          case 'depositHistory':
            state.depositHistory = message.data;
            break;
          case 'withdrawHistory':
            state.withdrawHistory = message.data;
            break;
          case 'streamBook':
            state.streamBook = message.data;
            break;
          case 'candles':
            var key = message.data.exchange + message.data.symbol + message.data.interval;
            state.candles[key] = message.data.candles;
            state.candlesVersion = state.candlesVersion + 1;
            break;
          case 'getBooksResponse':
            state.getBooksResponse = message.data;
            break;
          case 'moveFunds':
            state.moveFundsFlag = message.data.moveFundsFlag;
            break;
          case 'swapResponse':
            state.swapResponse = message.data;
            break;
          case 'exchangesFrontend':
            state.exchangesFrontend = message.data;
            break;
          case 'getExchangeConfig':
            state.exchangeConfiguration = message.data;
            break;
        }
      }
    }
  });