export default {
    methods: {
        getExchangeId(exchangeName) {
          const exchange = this.$store.state.exchangesFrontend.find(exchange => exchange.name === exchangeName);
          if (!exchange) { return null; }
          return exchange.cmcId;
        },
        getMarketLink(exchangeName, baseCurrency, quoteCurrency) {
          const exchange = this.$store.state.exchangesFrontend.find(exchange => exchange.name === exchangeName);
          if (!exchange) { return null; }

          let link = exchange.marketLink || null;

          link = link.replace('{baseCurrency_lowercase}', baseCurrency.toLowerCase());
          link = link.replace('{quoteCurrency_lowercase}', quoteCurrency.toLowerCase());
          link = link.replace('{baseCurrency_uppercase}', baseCurrency.toUpperCase());
          link = link.replace('{quoteCurrency_uppercase}', quoteCurrency.toUpperCase());

          return link;
          },
          getWithdrawLink(exchangeName, coinName) {
            const exchange = this.$store.state.exchangesFrontend.find(exchange => exchange.name === exchangeName);
            if (!exchange) { return null; }
  
            let link = exchange.withdrawLink || null;
  
            link = link.replace('{coinName_lowercase}', coinName.toLowerCase());
            link = link.replace('{coinName_uppercase}', coinName.toUpperCase());
  
            return link;
          },
          getDepositLink(exchangeName, coinName) {
            const exchange = this.$store.state.exchangesFrontend.find(exchange => exchange.name === exchangeName);
            if (!exchange) { return null; }
  
            let link = exchange.depositLink || null;
  
            link = link.replace('{coinName_lowercase}', coinName.toLowerCase());
            link = link.replace('{coinName_uppercase}', coinName.toUpperCase());
  
            return link;
          },
          isDex(exchangeName) {
            const exchange = this.$store.state.exchangesFrontend.find(exchange => exchange.name === exchangeName);
            if (!exchange) { return null; }
            return exchange.exchangeType === 'DEX';
          }
    }
}