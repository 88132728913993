<template>
  <div class="pa-1 mt-2">
    <v-row>
      <v-col class="d-flex align-center">
        <div>
          <v-img
            :src="
              'https://s2.coinmarketcap.com/static/img/exchanges/64x64/' +
              getExchangeId(configuration.exchange) +
              '.png'
            "
            class="mr-2 d-inline-block pointer"
            @click="copy2FA(configuration.exchange)"
            height="24"
            width="24"
          ></v-img>
        </div>
        <div class="text-subtitle-1 font-weight-bold">
          {{ configuration.exchange }}
          <a
            :href="getMarketLink(configuration.exchange, configuration.baseCurrency, configuration.quoteCurrency)"
            target="_blank"
            >({{ configuration.baseCurrency }}/{{ configuration.quoteCurrency }})</a
          >
        </div>
        <v-spacer></v-spacer>
        <div class="text-subtitle-1" v-if="configuration.quoteCurrency != 'USDT'">
          <v-icon class="mr-2" @click="openConversionDialog()">mdi-swap-horizontal-circle-outline</v-icon>
        </div>
        <div class="text-subtitle-1">
          <v-row dense
            ><v-col class="py-0 px-2 text-right text-body-2"
              >{{ addCommasAndFloor(quoteCurrencyBalance, getDecimalPositions(configuration.quoteCurrency)) }}
              {{ configuration.quoteCurrency }}</v-col
            ></v-row
          >
          <v-row dense
            ><v-col class="py-0 px-2 text-right text-body-2"
              >{{ addCommasAndFloor(baseCurrencyBalance, configuration.lotPrecision) }}
              {{ configuration.baseCurrency }}</v-col
            ></v-row
          >
        </div>
      </v-col>
    </v-row>
    <v-row dense class="px-2 mt-2">
      <v-col>
        <v-btn
          block
          depressed
          small
          color="success"
          @click="
            action = 'buy';
            roboSell = false;
          "
          :outlined="action != 'buy'"
          >Buy</v-btn
        >
      </v-col>
      <v-col>
        <v-btn block depressed small color="error" @click="action = 'sell'" :outlined="action != 'sell'">Sell</v-btn>
      </v-col>
    </v-row>
    <v-row dense class="px-2 mt-2">
      <v-col>
        <v-text-field
          v-if="action == 'buy'"
          outlined
          dense
          hide-details
          class="mb-2"
          label="Price"
          v-model="price"
        ></v-text-field>
        <v-text-field
          v-if="action == 'sell'"
          outlined
          dense
          hide-details
          class="mb-2"
          label="Price"
          v-model="price"
          :append-outer-icon="roboSell ? 'mdi-robot-off-outline' : 'mdi-robot-outline'"
          @click:append-outer="roboSell = !roboSell"
        ></v-text-field>
        <v-text-field
          outlined
          :disabled="roboSell"
          dense
          hide-details
          class="mb-2"
          label="Amount"
          v-model="amount"
          :suffix="`~ ${addCommasAndFloor(amount * price, 0)} $`"
        ></v-text-field>
        <v-slider
          hide-details
          :disabled="roboSell"
          :step="configuration.lotPrecision <= 6 ? precisionToDecimal(configuration.lotPrecision) : 0.000001"
          class="mb-2"
          v-model="amount"
          :max="action == 'buy' ? maxBuyableAmount : maxSellableAmount"
        ></v-slider>
        <v-row dense class="mb-2">
          <v-col
            ><v-btn
              block
              :outlined="!live || configuration.role != 'SOURCE' || action != 'buy'"
              small
              :color="live && configuration.role == 'SOURCE' && action == 'buy' ? 'success' : 'primary'"
              @click="updateSlider(0.25)"
              >25%</v-btn
            ></v-col
          >
          <v-col
            ><v-btn
              block
              :outlined="!live || configuration.role != 'SOURCE' || action != 'buy'"
              small
              :color="live && configuration.role == 'SOURCE' && action == 'buy' ? 'success' : 'primary'"
              @click="updateSlider(0.5)"
              >50%</v-btn
            ></v-col
          >
          <v-col
            ><v-btn
              block
              :outlined="!live || configuration.role != 'SOURCE' || action != 'buy'"
              small
              :color="live && configuration.role == 'SOURCE' && action == 'buy' ? 'success' : 'primary'"
              @click="updateSlider(0.75)"
              >75%</v-btn
            ></v-col
          >
          <v-col
            ><v-btn
              block
              :outlined="!live || configuration.role != 'SOURCE' || action != 'buy'"
              small
              :color="live && configuration.role == 'SOURCE' && action == 'buy' ? 'success' : 'primary'"
              @click="updateSlider(1)"
              >100%</v-btn
            ></v-col
          >
        </v-row>
        <v-row dense class="mb-2">
          <v-col v-if="isDex(configuration.exchange) && configuration.role == 'SOURCE' && action == 'buy'">
            <v-btn block depressed small color="primary" @click="buySend()">Buy & send</v-btn>
          </v-col>
          <v-col>
            <v-btn v-if="action == 'buy'" block depressed small color="success" @click="order('BUY')">Buy</v-btn>
            <v-btn v-if="action == 'sell'" block depressed small color="error" @click="order('SELL')">{{
              !roboSell ? "Sell" : "Robot sell"
            }}</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <MobileTradeDetails
      :configuration="configuration"
      :bookType="action == 'buy' ? 'asks' : 'bids'"
      @populate-fields="populateFields"
    />
    <v-dialog v-model="conversionDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
      <ConversionCard
        :conversionConfig="{
          currency: configuration.quoteCurrency,
          exchange: configuration.exchange,
        }"
        @close="closeConversionDialog()"
      />
    </v-dialog>
  </div>
</template>

<script>
import ConversionCard from "../ConversionCard.vue";
import MobileTradeDetails from "./MobileTradeDetails.vue";

import trades from "../../../../mixins/trades";

import _ from "lodash";

export default {
  name: "MobileTradeExchange",
  components: {
    ConversionCard,
    MobileTradeDetails,
  },
  props: ["configuration"],
  mixins: [trades],
  data: () => ({
    roboSell: false,
    conversionDialog: false,
    action: "buy",
    amount: 0,
    price: 0,
    //     tab: 0,
  }),
  computed: {
    live: function () {
      var opportunitiesKeys = this.$store.state.opportunities.map(function (opportunity) {
        return opportunity.key;
      });
      if (opportunitiesKeys.includes(this.$store.state.tradeWindow.key)) return true;
      return false;
    },
    baseCurrencyBalance: function () {
      var exchangeBalance = this.$store.state.balances[this.configuration.exchange];
      if (exchangeBalance === undefined) {
        return 0;
      }
      var balance = exchangeBalance[this.configuration.baseCurrency];
      if (balance === undefined) {
        return 0;
      }

      return balance;
    },
    quoteCurrencyBalance: function () {
      var exchangeBalance = this.$store.state.balances[this.configuration.exchange];
      if (exchangeBalance === undefined) {
        return 0;
      }
      var balance = exchangeBalance[this.configuration.quoteCurrency];
      if (balance === undefined) {
        return 0;
      }
      return balance;
    },
    maxBuyableAmount: function () {
      let maxBuyableUstd = this.quoteCurrencyBalance;

      if (this.live) {
        const liveData = this.updateLiveTrade(this.$store.state.opportunities);
        maxBuyableUstd = Math.min(this.quoteCurrencyBalance, liveData.maxBuyable / liveData.conversionRate);
      }

      return this.decimalFloor(maxBuyableUstd / this.price, Math.min(this.configuration.lotPrecision, 6)) || 0;
    },
    maxSellableAmount: function () {
      return (
        this.decimalFloor(
          this.baseCurrencyBalance,
          this.configuration.lotPrecision <= 6 ? this.configuration.lotPrecision : 6
        ) || 0
      );
    },
  },
  methods: {
    updateSlider(percentage) {
      this.amount = this.action == "buy" ? this.maxBuyableAmount * percentage : this.maxSellableAmount * percentage;
    },
    openConversionDialog() {
      this.conversionDialog = true;
    },
    closeConversionDialog() {
      this.conversionDialog = false;
    },
    resetAction() {
      if (this.configuration.role == "SOURCE") {
        this.action = "buy";
        this.price = this.configuration.firstAsk;
      }
      if (this.configuration.role == "DESTINATION") {
        this.action = "sell";
        this.price = this.configuration.firstBid;
      }
    },
    populateFields(entry) {
      this.amount = entry.volume;
      this.price = entry.price;
    },
    order(side) {
      var message = {
        target: "trade",
        action: !this.roboSell ? "placeLimitOrder" : "placeRobotOrder",
        exchange: this.configuration.exchange,
        market: this.configuration.symbol,
        side: side,
        price: this.price,
        amount: this.amount,
      };
      this.$store.state.connection.send(JSON.stringify(message));
    },
    buySend() {
      if (this.$store.state.depositAddress.address == null) return;

      var message = {
        target: "trade",
        action: "swapSendOrder",
        exchange: this.configuration.exchange,
        market: this.configuration.symbol,
        //           side: 'BUY',
        price: this.price,
        amount: this.amount,
        recipient: this.$store.state.depositAddress.address,
      };
      if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
        this.$store.state.connection.send(JSON.stringify(message));
      }
    },
  },
  mounted() {
    this.resetAction();
    //    this.price = this.configuration.firstAsk;
  },
  watch: {
    configuration: function () {
      this.resetAction();
      //        this.price = this.configuration.firstAsk;
    },
    price: function () {
      // this.price = this.decimalRound(this.price, this.configuration.tickPrecision);
      this.debouncedPrice();
    },
  },
  created() {
    this.debouncedPrice = _.debounce(() => {
      if (this.action == "buy") {
        this.price = this.decimalCeil(this.price, this.configuration.tickPrecision);
      } else {
        this.price = this.decimalFloor(this.price, this.configuration.tickPrecision);
      }
    }, 1000);
  },
};
</script>

<style scoped>
</style>