<template>
    <div v-if="Object.keys(tradeWindow).length !== 0">
        <div class="live-alert" v-if="opportunitiesKeys.includes(tradeWindow.key) && liveData.maxBuyable != 0"><v-alert dense type="success" icon="mdi-radio-tower">ABP: {{formatNumberForView(decimalFloor(liveData.avgBuyPrice, tradeWindow.source.tickPrecision))}} &dollar; | MBP: {{formatNumberForView(decimalFloor(liveData.maxBuyPrice, tradeWindow.source.tickPrecision))}} &dollar; | ASP: {{formatNumberForView(decimalFloor(liveData.avgSellPrice, tradeWindow.destination.tickPrecision))}} &dollar; | MB: {{formatNumberForView(decimalFloor(liveData.maxBuyable, 0))}} &dollar; | AP: {{formatNumberForView(decimalFloor(liveData.actualProfit, 0))}} &dollar; | MP: {{formatNumberForView(decimalFloor(liveData.maxProfit, 0))}} &dollar;</v-alert></div>
        <v-row class="mb-1">
            <DesktopTradeExchange :configuration="tradeWindow.source" />
            <v-divider vertical inset></v-divider>
            <DesktopTradeExchange :configuration="tradeWindow.destination" />
        </v-row>
        <v-divider></v-divider>
        <v-row dense>
            <v-col class="col-9">
              <TradeDetails />
            </v-col>
            <v-divider vertical inset></v-divider>
                <TradeTransfer />
        </v-row>
        <v-dialog v-model="swapDialog" max-width="500">
            <SwapCard @close="closeSwapDialog()" />
        </v-dialog>
</div>
</template>

<script>
import DesktopTradeExchange from './DesktopTradeExchange.vue';
import TradeDetails from '../commons/TradeDetails.vue';
import TradeTransfer from '../commons/TradeTransfer.vue';
import SwapCard from '../SwapCard.vue';

import trades from '../../../../mixins/trades';

export default {
    name: "DesktopTradeWindow",
    mixins: [trades],
    components: {
    DesktopTradeExchange,
    TradeDetails,
    TradeTransfer,
    SwapCard,
},
    computed: {
        tradeWindow: function() {
            return this.$store.state.tradeWindow;
        },
        connectionReady: function() {
            if (this.$store.state.connection !== null) {
                return this.$store.state.connection.readyState;
            }
            else {
                return WebSocket.CLOSED;
            }  
        },
        opportunitiesKeys: function() {
            var opportunities = this.$store.state.opportunities;
            return opportunities.map(function(opportunity) {
                return opportunity.key;
            });
        },
        opportunities: function() {
            return this.$store.state.opportunities;
        },
        swapResponse: function() {
            return this.$store.state.swapResponse;
        },
    },
    methods: {
        subscribeStreamBook() {
            if (Object.keys(this.tradeWindow).length === 0) return;

            var message = {
                target: 'streaming',
                action: 'subscribe',
                channel: {
                    coinName: this.tradeWindow.source.baseCurrency,
                    buyFrom: this.tradeWindow.source.exchange,
                    buyFromQuoteCurrency: this.tradeWindow.source.quoteCurrency,
                    buyFromSymbol: this.tradeWindow.source.symbol,
                    sellTo: this.tradeWindow.destination.exchange,
                    sellToQuoteCurrency: this.tradeWindow.destination.quoteCurrency,
                    sellToSymbol: this.tradeWindow.destination.symbol,
                }
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        },
        unsubscribeStreamBook() {
            var message = {
                target: 'streaming',
                action: 'unsubscribe',
                
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        },
        closeSwapDialog() {
            this.swapDialog = false;
        },
    },
    watch: {
        tradeWindow: function () {
           this.subscribeStreamBook();
        //   Object.assign(this.$data, this.$options.data());
        //   this.liveData = this.tradeWindow;
         this.liveData = this.updateLiveTrade(this.opportunities);
        },
        opportunities: function () {
           this.liveData = this.updateLiveTrade(this.opportunities);
        },
        connectionReady: function () {
            if (this.connectionReady == WebSocket.OPEN) {
                this.subscribeStreamBook();
            }
        },
        swapResponse: function () {
            if (this.swapResponse != null) {
                this.swapDialog = true;
            }
        }
    },
//     mounted () {
//         this.subscribeStreamBook();
//   //      this.liveData = this.tradeWindow;
//     },
    beforeDestroy() {
         this.unsubscribeStreamBook();
    },
    data: () => ({
        liveData: {
            avgBuyPrice: 0,
            maxBuyPrice: 0,
            avgSellPrice: 0,
            maxBuyable: 0,
            actualProfit: 0,
            maxProfit:0,
        },
        swapDialog: false,
    }),
}
</script>

<style scoped>

.live-alert {
  position: fixed;
  top: 14px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

</style>