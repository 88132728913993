<template>
          <v-row dense class="px-2">
            <v-col>
                <v-tabs v-model="tab" grow @change="refresh()">
                    <v-tab value="book"><v-icon>mdi-book-open-variant</v-icon></v-tab>
                    <v-tab value="openOrders"><v-icon>mdi-clipboard-text-outline</v-icon></v-tab>
                    <v-tab value="ordersHistory"><v-icon>mdi-clipboard-text-clock-outline</v-icon></v-tab>
                    <v-tab value="outgoing"><v-icon v-if="configuration.role == 'SOURCE'">mdi-bank-transfer-out</v-icon><v-icon v-if="configuration.role == 'DESTINATION'">mdi-bank-transfer-in</v-icon></v-tab>
                    <!-- <v-tab value="transfer"><v-icon>mdi-swap-horizontal-bold</v-icon></v-tab> -->
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <MobileBookTable :exchange="configuration.exchange" :symbol="configuration.symbol" :tickPrecision="configuration.tickPrecision" @populate-fields="populateFields" :bookType="bookType" :lotPrecision="configuration.lotPrecision" />
                    </v-tab-item>
                    <v-tab-item>
                        <MobileOrderList :exchange="configuration.exchange" :baseCurrency="configuration.baseCurrency" :quoteCurrency="configuration.quoteCurrency" :history="false" :tickPrecision="configuration.tickPrecision" :lotPrecision="configuration.lotPrecision" />
                    </v-tab-item>
                    <v-tab-item>
                        <MobileOrderList :exchange="configuration.exchange" :baseCurrency="configuration.baseCurrency" :quoteCurrency="configuration.quoteCurrency" :history="true" :tickPrecision="configuration.tickPrecision" :lotPrecision="configuration.lotPrecision" />
                    </v-tab-item>
                    <v-tab-item>
                        <MobileTransferList :exchange="configuration.exchange" :currency="configuration.baseCurrency" :type="(configuration.role == 'SOURCE') ? 'withdraw' : 'deposit'" />
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
</template>

<script>
import MobileBookTable from './MobileBookTable.vue';
import MobileOrderList from './MobileOrderList.vue';
import MobileTransferList from './MobileTransferList.vue';


export default {
    name: "MobileTradeDetails",
    components: {
        MobileBookTable,
        MobileOrderList,
        MobileTransferList,
    },
    data: () => ({
       tab: 0,
    }),
    props: ['configuration', 'bookType'],
       methods: {
        updateOpenOrders(tradeWindow) {
            var message = {
                target: 'trade',
                action: 'updateOpenOrders',
                exchange: tradeWindow.exchange,
                baseCurrency: tradeWindow.baseCurrency,
                quoteCurrency: tradeWindow.quoteCurrency,
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        },
        getOrderHistory(exchange, market) {
            var message = {
                target: 'trade',
                action: 'getOrderHistory',
                exchange: exchange,
                market: market,
            };
            
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        },
        getTransferHistory(exchange, currency, type) {
            var action = (type == 'deposit') ? 'getDepositHistory' : 'getWithdrawHistory';
            var message = {
                target: 'wallet',
                action: action,
                exchange: exchange,
                currency: currency,
            };   
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        },
        refresh() {
            if (this.tab == 1) {
                this.updateOpenOrders(this.configuration);
                return;
            }
            if (this.tab == 2) {
                this.getOrderHistory(this.configuration.exchange, this.configuration.symbol);
                return;
            }
            if (this.tab == 3) {
                this.getTransferHistory(this.configuration.exchange, this.configuration.baseCurrency, (this.configuration.role == 'SOURCE') ? 'withdraw' : 'deposit');
                return;
            }
        },
        populateFields(entry) {
            this.$emit('populate-fields', entry);
        },
    },
    computed: {
        tradeWindow: function() {
            return this.$store.state.tradeWindow;
        },
    },
    watch: {
        tradeWindow: function() {
            this.refresh();
        }
    },
    mounted() {
       this.intervalFn = setInterval(() => {this.refresh();}, 3000);
    },
    beforeDestroy() {
        clearInterval(this.intervalFn);
    }

}
</script>

<style scoped>

</style>