import { render, staticRenderFns } from "./MobileChartsCard.vue?vue&type=template&id=b5aa2050&scoped=true&"
import script from "./MobileChartsCard.vue?vue&type=script&lang=js&"
export * from "./MobileChartsCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b5aa2050",
  null
  
)

export default component.exports