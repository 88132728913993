<template>
  <v-card>
      <v-card-text>
          <v-row class="px-5 pt-6">
              <v-col class="text-center justify-center align-center d-flex">
                <v-sheet>
                  <v-img width="56" src="https://s2.coinmarketcap.com/static/img/coins/64x64/825.png" ></v-img>
                  <div class="text-h6 font-weight-bold mt-3">USDT: {{addCommas(usdtAmount)}}</div>
                </v-sheet>
                </v-col>
                <v-col class="text-center justify-center align-center d-flex">
                <v-sheet>
                    <v-icon x-large>mdi-swap-horizontal-bold</v-icon>
                </v-sheet>
                </v-col>
                <v-col class="text-center justify-center align-center d-flex">
                <v-sheet>
                  <v-img width="56" :src="'https://s2.coinmarketcap.com/static/img/coins/64x64/' + getCoinId(conversionConfig.currency) + '.png'"></v-img>
                  <div class="text-h6 font-weight-bold mt-3">{{conversionConfig.currency}}: {{addCommas(conversionCurrencyAmount)}}</div>
                </v-sheet>
              </v-col>
          </v-row>
          <v-row>
              <v-col>
                  <v-text-field outlined dense hide-details class="mb-2" label="Amount" v-model="buyAmount"></v-text-field>
                  <v-slider hide-details :step="this.getDecimalStep(usdtAmount)" class="mb-2" v-model="buyAmount" :max="usdtAmount"></v-slider>
                  <v-btn block depressed color="success" @click="buy()">USDT <v-icon>mdi-arrow-right-thin</v-icon> {{conversionConfig.currency}}</v-btn>
              </v-col>
              <v-col>
                  <v-text-field outlined dense hide-details class="mb-2" label="Amount" v-model="sellAmount"></v-text-field>
                  <v-slider hide-details :step="this.getDecimalStep(conversionCurrencyAmount)" class="mb-2" v-model="sellAmount" :max="conversionCurrencyAmount"></v-slider>
                  <v-btn block depressed color="error" @click="sell()">{{conversionConfig.currency}} <v-icon>mdi-arrow-right-thin</v-icon> USDT</v-btn>
              </v-col>
          </v-row>
      </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-4">
            <!-- <v-checkbox v-model="closeAfterConfirm" label="Close dialog after confirm"></v-checkbox> -->
            <v-spacer></v-spacer>
            <v-btn color="primary" outlined @click="close()">Close</v-btn>
            <!-- <v-btn color="primary" @click="confirm()" :disabled="amount == 0 || destinationExchange == 'none'">Confirm</v-btn> -->
        </v-card-actions>
  </v-card>
</template>

<script>
export default {
    name: "ConversionCard",
    props: ['conversionConfig'],
    data: () => ({
        buyAmount: 0,
        sellAmount: 0,
    }),
    methods: {
        buy() {
            var message = {
                target: 'trade',
                action: 'convert',
                side: 'BUY',
                exchange: this.conversionConfig.exchange,
                currency: this.conversionConfig.currency,
                amount: this.buyAmount,
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        },
        sell() {
            var message = {
                target: 'trade',
                action: 'convert',
                side: 'SELL',
                exchange: this.conversionConfig.exchange,
                currency: this.conversionConfig.currency,
                amount: this.sellAmount,
            };
            if (this.$store.state.connection != null && this.$store.state.connection.readyState === WebSocket.OPEN) {
                this.$store.state.connection.send(JSON.stringify(message));
            }
        },
        close() {
            Object.assign(this.$data, this.$options.data());
            this.$emit('close');
      },
    },
    computed: {
        usdtAmount: function() {
            var balances = this.$store.state.balances;
            var exchangeBalances = balances[this.conversionConfig.exchange];
            if (exchangeBalances === undefined) return 0;
            var currencyBalance = exchangeBalances["USDT"];
            if (currencyBalance === undefined) return 0;
            if (this.getDecimalPlaces(currencyBalance) >= 6) return this.decimalFloor(currencyBalance, 6);
            return currencyBalance;
        },
        conversionCurrencyAmount: function() {
            var balances = this.$store.state.balances;
            var exchangeBalances = balances[this.conversionConfig.exchange];
            if (exchangeBalances === undefined) return 0;
            var currencyBalance = exchangeBalances[this.conversionConfig.currency];
            if (currencyBalance === undefined) return 0;
            if (this.getDecimalPlaces(currencyBalance) >= 6) return this.decimalFloor(currencyBalance, 6);
            return currencyBalance;
        }
    }
}
</script>

<style scoped>

</style>