export default {
    methods: {
        copy2FA(exchange) {
            var totp = this.$store.state.totps[exchange];
            navigator.clipboard.writeText(String(totp).padStart(6, "0"));
        },
        copy2Clipboard(text) {
            navigator.clipboard.writeText(text);
        },
    }
}